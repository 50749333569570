import { initialCrmEmailState } from '../../../../../Store';

export const sendEmailToContacts = async ({
  contactsToSendIds,
  getAccessToken,
  customApiCall,
  query,
  navigate,
  requestHeaders,
  filters,
  setIsLoading,
  focusToField,
  listDomainsVerified,
}) => {
  const unverifiedEmail =
    !listDomainsVerified.verifiedDomains.length && !listDomainsVerified.verifiedEmailAddresses.length;
  if (unverifiedEmail) {
    navigate('/influence/emails', { replace: true });
    return;
  }
  setIsLoading(true);
  let method = 'post';
  let pathname = '/api/crm-email/save';

  let token = await getAccessToken();
  requestHeaders.headers = {
    Authorization: `Bearer ${token}`,
  };
  let requestProperties = {
    Body: initialCrmEmailState().body,
    Title: '',
    EmailDomainId: 1,
    CrmEmailId: null,
    SenderEmailAddressId: JSON.parse(localStorage.getItem('lastSenderEmailAddress'))?.emailAddressId,
  };
  let successMessage = null;

  const result = await customApiCall({
    method,
    pathname,
    requestHeaders,
    requestProperties,
    successMessage,
  });

  if (result.crmEmailId) {
    let method = 'post';
    let pathname = contactsToSendIds === 'all' ? '/api/crm-email/add-contacts-by-query' : '/api/crm-email/add-contacts';
    let token = await getAccessToken();
    requestHeaders.headers = {
      Authorization: `Bearer ${token}`,
    };
    const searchQuery = query.get('search');
    let requestProperties =
      contactsToSendIds === 'all'
        ? {
            crmEmailId: result.crmEmailId,
            filters: filters,
            query: searchQuery || '',
          }
        : {
            crmEmailId: result.crmEmailId,
            crmContactIds: contactsToSendIds,
          };
    let successMessage = null;
    const addContactsToList = await customApiCall({
      method,
      pathname,
      requestHeaders,
      requestProperties,
      successMessage,
    });
    if (addContactsToList.crmEmailId) {
      navigate(
        {
          pathname: `/influence/emails/${addContactsToList.crmEmailId}`,
          search: focusToField ? '?focusToField=true' : '',
        },
        {
          state: focusToField ? { autofocusFieldFromUrl: 'to' } : {},
          replace: true,
        }
      );
    } else {
      setIsLoading(false);
    }
  } else {
    setIsLoading(false);
  }
};
