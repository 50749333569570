import Axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useGetKeywordsSuggestions from './useGetKeywordsSuggestions';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Mousewheel } from 'swiper/modules';
import _ from 'lodash';

function KeywordsSuggestions({ topicName, onSelect = () => {}, setShowSuggestion, createPage }) {
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getKeywordsSuggestions } = useGetKeywordsSuggestions();
  const suggestionsListRef = useRef();
  const getSuggestions = useRef();
  const arrowRef = useRef(null);
  getSuggestions.current = async (name, source) => {
    try {
      const suggestionsRes = await getKeywordsSuggestions({ topicName: name, source });
      if (suggestionsRes) {
        setSuggestions(suggestionsRes);
        setIsLoading(false);
      }
    } catch (e) {}
  };

  const debouncedGetSuggestionsCall = useCallback(_.debounce(getSuggestions.current, 300), []);

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    if (topicName && !!topicName?.length) {
      setIsLoading(true);
      debouncedGetSuggestionsCall(topicName, source);
    } else {
      setSuggestions([]);
      setIsLoading(false);
    }
    return () => {
      source.cancel('Keywords suggestions api call canceled by the user');
    };
  }, [topicName]);

  return (
    <div className='topic-suggestions-container px-2 pr-5 py-2 border-bottom'>
      <div className='d-flex'>
        <div className={`position-relative w-100`} style={{ minHeight: '28.6px' }}>
          {isLoading ? (
            <KeywordsSuggestionsSkeleton createPage={createPage} />
          ) : (
            <>
              {!topicName || !topicName?.length ? (
                <p
                  className='topic-suggestions-container-placeholder'
                  style={{ padding: createPage ? '0 5px' : '0 5px 0 10px' }}
                >
                  Suggested keywords will appear here once you add a topic name
                </p>
              ) : (
                suggestions.length > 0 && (
                  <div style={{ padding: createPage ? '0 5px' : '0 5px 0 10px' }}>
                    <Swiper
                      slidesPerView={'auto'}
                      spaceBetween={12}
                      loop={true}
                      modules={[Navigation, Mousewheel]}
                      className='topic-suggestions-list'
                      navigation={{
                        nextEl: '.topic-suggestions-scroll-arrow.direction-right',
                      }}
                      slidesPerGroup={1}
                      mousewheel={true}
                    >
                      <div className={`topic-suggestions-list overflow-hidden w-100`} ref={suggestionsListRef}>
                        {suggestions.map((item) => {
                          return (
                            <SwiperSlide>
                              <div
                                className='topic-suggestions-list-item paragraph-p1'
                                onClick={() => {
                                  onSelect(item);
                                  let newSuggestions = suggestions.filter((keyword) => keyword !== item);
                                  setSuggestions(newSuggestions);
                                  if (newSuggestions.length === 0) {
                                    setShowSuggestion(false);
                                  }
                                }}
                              >
                                {item}
                                <span className='keyword-suggestion-plus'>+</span>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </div>
                    </Swiper>
                    <button
                      ref={arrowRef}
                      className={`general-button topic-suggestions-scroll-arrow direction-right`}
                    ></button>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const KeywordsSuggestionsSkeleton = ({ createPage }) => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div
        className='topic-suggestions-list overflow-hidden w-100'
        style={{ marginTop: '-3px', paddingLeft: createPage ? '5px' : '10px' }}
      >
        <div className='rounded'>
          <Skeleton height={28} width={`80px`} />
        </div>
        <div className='rounded'>
          <Skeleton height={28} width={`120px`} />
        </div>
        <div className='rounded'>
          <Skeleton height={28} width={`60px`} />
        </div>
        <div className='rounded'>
          <Skeleton height={28} width={`140px`} />
        </div>
        <div className='rounded'>
          <Skeleton height={28} width={`100px`} />
        </div>
        <div className='rounded'>
          <Skeleton height={28} width={`60px`} />
        </div>
        <div className='rounded'>
          <Skeleton height={28} width={`90px`} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default KeywordsSuggestions;
