import { useContext, useState } from 'react';
import useGeneralApiCall from '../../apiCalls/useGeneralApiCall';
import { store } from '../../../Store';

const useStakeholderNotesFunctions = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;

  const [userNotes, setUserNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { generalApiCall } = useGeneralApiCall();

  const getNotes = async ({ objectId, requestSource, contentType }) => {
    setIsLoading(true);
    const pathname = `/api/crm-activity/list?objectId=${objectId}&contentType=${contentType ?? 'Stakeholder'}`;
    const method = 'get';
    const results = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      requestSource,
      notShowErrorMessage: true,
    });
    if (results) {
      setUserNotes(results);
    }
    setIsLoading(false);
  };

  const addNote = async ({ objectId, text, contentType, visibility }) => {
    const pathname = `/api/crm-activity/create`;
    const method = 'post';
    const requestProperties = {
      contentType: contentType ?? 'Stakeholder',
      objectId,
      text,
      activityType: 'Note',
    };
    if (visibility) {
      requestProperties.isOrganisationWide = visibility === 'agency';
    }
    const results = await generalApiCall({ pathname, requestProperties, method, needsAuthentication: true });
    if (results) {
      setUserNotes([results, ...userNotes]);
    }
  };

  const updateNote = async ({ note, text }) => {
    const pathname = `/api/crm-activity/update`;
    const method = 'post';
    const requestProperties = {
      ...note,
      text,
    };
    const results = await generalApiCall({ pathname, requestProperties, method, needsAuthentication: true });
    if (results) {
      let newUserNotes = [...userNotes];
      let noteItemPosition = newUserNotes.findIndex((item) => item?.id === note?.id);
      if (noteItemPosition !== undefined && noteItemPosition >= 0) {
        newUserNotes[noteItemPosition] = results;
      }
      setUserNotes(newUserNotes);
    }
  };

  const deleteNote = async ({ id }) => {
    const pathname = `/api/crm-activity/${id}`;
    const method = 'delete';
    const result = await generalApiCall({ pathname, method, needsAuthentication: true });
    if (result) {
      let newNotes = [...userNotes];
      let itemPosition = newNotes.findIndex((item) => item.id === id);
      if (itemPosition !== undefined) {
        newNotes.splice(itemPosition, 1);
        setUserNotes(newNotes);
      }
    }
  };

  const modifyNote = ({ note, text }) => {
    let newUserNotes = [...userNotes];
    let noteItemPosition = newUserNotes.findIndex((item) => item?.id === note?.id);
    if (noteItemPosition) {
      let newItem = { ...newUserNotes[noteItemPosition], text };
      newUserNotes[noteItemPosition] = newItem;
      setUserNotes(newUserNotes);
    }
  };

  const isNoteCreator = ({ note }) => {
    return note?.authorEmailAddress === team?.myself?.emailAddress;
  };

  return { userNotes, isLoading, getNotes, addNote, modifyNote, updateNote, isNoteCreator, deleteNote };
};

export default useStakeholderNotesFunctions;
