import React, { useState } from 'react';
import AddKeywordsComponent from './AddKeywordsComponent';
const AdminTopicKeywords = (props) => {
  const [showSuggestion, setShowSuggestion] = useState(!props?.topicInformation?.id);
  return (
    <>
      <div className='topic-items-container padding-topic-component pb-5'>
        <div className={`mb-3 d-flex justify-content-between align-items-center`}>
          <div>
            <h3 className='topic-title mb-0'>Keywords and phrases</h3>
            <p className='mb-0 main-light-text'>Enter the keywords and phrases related to this topic</p>
          </div>
          <button
            className='general-button paragraph-p1-bold main-light-text align-self-start'
            onClick={() => {
              setShowSuggestion(!showSuggestion);
            }}
          >
            {showSuggestion ? 'Hide' : 'Show'} suggestions
          </button>
        </div>
        <div className='topics-search-items'>
          <div className='px-3 pb-4 pt-3'>
            <p className='keywords-rule-title mb-2 pl-1'>
              <span className='text-uppercase'>
                <strong>Any of</strong> these keywords
              </span>
            </p>
            <AddKeywordsComponent
              {...props}
              parameter={'keywords'}
              showSuggestion={showSuggestion}
              setShowSuggestion={setShowSuggestion}
            />
          </div>
          <p className={`keywords-rule-title add-rule-title py-2 px-4`}>
            <span className='text-uppercase'>
              <strong>+ And also</strong> any of these keywords
            </span>
          </p>
          <div className='px-4'>
            <AddKeywordsComponent
              {...props}
              parameter={'andKeywords'}
              showSuggestion={showSuggestion}
              setShowSuggestion={setShowSuggestion}
            />
          </div>

          <p className={`keywords-rule-title add-rule-title py-2 px-4`}>
            <span className='text-uppercase'>
              <strong>+ But not</strong> any of these keywords
            </span>
          </p>
          <div className='px-4'>
            <AddKeywordsComponent
              {...props}
              parameter={'keywordExclusions'}
              showSuggestion={showSuggestion}
              setShowSuggestion={setShowSuggestion}
            />
          </div>

          <p className={`keywords-rule-title add-rule-title py-2 px-4`}>
            <span className='text-uppercase'>
              <strong>Parliament Today hand-picked keywords</strong>
            </span>
          </p>
          <div className='px-4'>
            <AddKeywordsComponent
              {...props}
              parameter={'ptHandPickedContentKeywords'}
              excludeDuplicatedWordsRule
              showSuggestion={showSuggestion}
              setShowSuggestion={setShowSuggestion}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTopicKeywords;
