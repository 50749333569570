import React, { useCallback, useContext, useRef } from 'react';
import ContentSummary from '../components/ContentSummary';
import Share from '../components/Share';
import useTitleScrolledStyles from '../hooks/useTitleScrolledStyles';
import ThumbsDown from '../../Common/ThumbsDown';
import FeedbackOnItem from '../FeedbackItem/FeedbackOnItem';
import { store } from '../../Store';
import TextSelector from '../../Common/TextSelector';
const dayjs = require('dayjs');

const LibraryMaterialMainContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults } = state;

  const { LibraryMaterial } = contentResults[activeResults];
  const { currentNew, contentContainer, hideSummary, reportWidget, scrollbarRef } = props;
  const titleSection = useRef(null);
  let { showFixedTitle, titleStyles } = useTitleScrolledStyles(contentContainer, titleSection);

  const HeadingTitle = () => {
    return (
      <h3
        className='main-title news-title pr-lg-4'
        dangerouslySetInnerHTML={createMarkup(currentNew.titleHighlighted ?? currentNew.title)}
      />
    );
  };

  const createMarkup = (body) => {
    return { __html: body };
  };

  const updateLinks = useRef();
  updateLinks.current = () => {
    let links = document.querySelectorAll('.library-material-content a');
    links.forEach((item) => {
      if (item) {
        item.setAttribute('target', '_blank');
      }
    });
  };

  const libraryMaterialBody = useCallback((node) => {
    if (node !== null) {
      updateLinks.current();
    }
  }, []);

  const alreadyMarkedFeedback = !!currentNew?.feedback;
  const index = LibraryMaterial ? LibraryMaterial?.hits?.findIndex((item) => item.id === currentNew.id) : null;
  const ThumbsDownProperties = {
    item: { objectID: currentNew?.objectId },
    nameSection: 'LibraryMaterial',
    show: !!currentNew?.keywordsFromLists,
    contentPage: true,
    alreadyMarked: alreadyMarkedFeedback,
    keywordsFromLists: currentNew?.keywordsFromLists,
    thick: true,
  };
  return (
    <>
      <div
        className={`section-title pb-2 ${reportWidget ? 'content-title-in-widget' : 'content-title-with-summary'} library-material-title content-border-title`}
        ref={titleSection}
      >
        <HeadingTitle />
        <div className={`row mx-0 justify-content-between align-items-center pt-2`}>
          <p className='heading-date mb-0'>
            <span className='date'>{dayjs(currentNew.date).format('DD MMMM YYYY')}</span>
          </p>
          <div className='flex-centered'>
            <ThumbsDown {...ThumbsDownProperties} />
            <ShareButtonRender currentNew={currentNew} />
          </div>
        </div>
      </div>
      <ContentSummary content={currentNew} hide={hideSummary} />
      <div className='main-update-content-container'>
        <div className='px-3 px-lg-4 pb-3 pb-lg-4 specific-content-container'>
          {/* FIXED TITLE */}
          <div
            className={`content-item-scrolled-title section-title bg-white ${showFixedTitle && !reportWidget ? 'item-scrolled-title-visible' : ''}`}
            style={titleStyles}
          >
            <div className='d-flex justify-content-between scrolled-title-container'>
              <HeadingTitle />
              <ThumbsDown {...ThumbsDownProperties} />
              <ShareButtonRender currentNew={currentNew} />
            </div>
          </div>
          {/* END FIXED TITLE */}
          {currentNew?.types?.length > 0 && (
            <p className='pt-3 pb-3 mb-0'>
              {currentNew.types.map((item, index) => (
                <span
                  key={`types-${index}`}
                  className='paragraph-p6 text-uppercase border-grey box-rounded px-4 py-1 mr-2 d-inline-block'
                >
                  {item}
                </span>
              ))}
            </p>
          )}
          {alreadyMarkedFeedback && (
            <div className='pb-4'>
              <FeedbackOnItem
                contentPage={true}
                item={{ objectID: currentNew?.objectId }}
                feedback={currentNew?.feedback}
                keywordsFromLists={currentNew?.keywordsFromLists}
                index={index}
              />
            </div>
          )}

          {currentNew?.authors?.length > 0 && (
            <p className='mb-2 paragraph-p2'>
              {currentNew?.authors?.map((item) => {
                return (
                  <span key={`item-${item}`} className='d-inline-block mr-3'>
                    <span className='paragraph-p4 icon-user mr-1 d-inline-block' /> {item}
                  </span>
                );
              })}
            </p>
          )}
          {currentNew?.url && (
            <a
              className='mb-3 d-block simple-link-button'
              href={currentNew?.url}
              target={'_blank'}
              rel='noopener noreferrer'
            >
              Source: House of Commons Library
            </a>
          )}

          <TextSelector currentNew={currentNew} scrollbarRef={scrollbarRef}>
            <div
              ref={libraryMaterialBody}
              className='news-content-in pt-2 pb-3 pr-3 context mb-2 library-material-content'
              dangerouslySetInnerHTML={createMarkup(currentNew.body)}
            />

            {((!!currentNew.otherSupportingDocuments &&
              currentNew.otherSupportingDocuments.length > 0 &&
              !currentNew?.extract) ||
              !!currentNew.fullReportLinkUrl) && (
              <>
                <div className='section-title pb-3 mb-3 library-material-documents-to-download'>
                  <h2 className='title-h4-bold mb-3'>Documents to download</h2>
                  {!!currentNew.fullReportLinkUrl && (
                    <>
                      <p>{currentNew.fullReportLinkName}</p>
                      <a
                        className='action-button px-4 py-2 mb-4 d-inline-block'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={currentNew.fullReportLinkUrl}
                        download
                      >
                        Download full report
                      </a>
                    </>
                  )}
                  {!!currentNew.otherSupportingDocuments && currentNew.otherSupportingDocuments.length > 0 && (
                    <>
                      {!!currentNew.fullReportLinkUrl && (
                        <>
                          <h3 className='general-complementary-title mb-3'>Supporting documents</h3>
                        </>
                      )}
                      <ul className='policy-list px-0'>
                        {currentNew.otherSupportingDocuments.map((item, index) => {
                          return (
                            <a
                              key={`attachment-${index}`}
                              href={item}
                              target={'_blank'}
                              rel='noopener noreferrer'
                              download
                              className='attachment-box'
                            >
                              <span className='attachment-file-name'>
                                {
                                  item
                                    .replace('https://researchbriefings.files.parliament.uk/documents/', '')
                                    .split('/')[1]
                                }
                              </span>
                              <button className={`general-button attachment-button`}> </button>
                            </a>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </div>
              </>
            )}
          </TextSelector>
        </div>
      </div>
    </>
  );
};

const ShareButtonRender = (props) => {
  const { currentNew, shareButtonWidget } = props;
  const { id, title, canonicalUrl, objectId, objectType } = currentNew;
  let item = {
    id,
    title,
    objectID: objectId,
    type: objectType,
  };
  const ShareOptions = {
    item,
    id: item.id,
    title: item.title,
    shareButtonWidget,
  };

  if (canonicalUrl) {
    ShareOptions.link = `${process.env.REACT_APP_APPURL}${canonicalUrl}`;
  }

  return <Share {...ShareOptions} />;
};

export default LibraryMaterialMainContent;
