import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { store } from '../../Store.js';
import ContentRelated from '../key_updates/ContentRelated';
import axios from 'axios';
import callContentRelated from '../utilities/callContentRelated.js';
import normalizeString from '../utilities/normalizeString';
import SkeletonConsultationContent from './SkeletonConsultationContent.js';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions.js';
import callHightlightContentAPI from '../utilities/callHightlightContentAPI.js';
import { setFiltersLocalStorage } from '../utilities/setFiltersLocalStorage.js';
import { useAuth0 } from '@auth0/auth0-react';
import addMetaTags from '../utilities/addMetaTags.js';
import { useHeightContainer } from '../utilities/useHeightContainer.js';
import ConsultationNewContent from './ConsultationNewContent.js';
import { getIdPositionOnSection } from '../helpers/navigationFunctions.js';
import updateUnreadItems from '../helpers/updateUnreadItems.js';
import useGetHighlightingKeywords from '../hooks/useGetHighlighingKeywords.js';
import useCallCollectContent from '../hooks/useCallCollectContent.js';
import useGetMatchingKeywordsListsForContent from '../hooks/useMatchingKeywordsListsForContent.js';
import CustomScrollbar from '../../Common/CustomScrollbar.js';
import useGetAccessToken from '../apiCalls/useGetAccessToken.js';

const ConsultationContent = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const [heightContainer, containerRef] = useHeightContainer();
  const { contentResults, activeResults, net_api_url, search, referenceState } = state;
  const { searchState, activeSearch } = search;
  const { activeReference } = referenceState;

  const { Consultations } = contentResults[activeResults];

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRelatedContent, setIsLoadingRelatedContent] = useState(true);
  const [scrolling, setScrolling] = useState(false);

  const location = useLocation();
  const params = useParams();

  const { id } = params;
  const { getAccessToken } = useGetAccessToken();
  const { isAuthenticated } = useAuth0();

  const getIdPosition = getIdPositionOnSection(Consultations, id);
  const [idPosition, setIdPosition] = useState(getIdPosition());
  const { andKeywords, queryHighlight } = useGetHighlightingKeywords();
  const { getMatchingKeywordsListsAndFeedback } = useGetMatchingKeywordsListsForContent();
  const { callCollectContent } = useCallCollectContent();

  const contentContainer = useRef(null);
  const scrollbarRef = useRef(null);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (!isAuthenticated || searchState) {
      setIdPosition(getIdPosition());
      loadNew(source);
    }
    return () => {
      source.cancel('Current new cancelled by the user.');
    };
  }, [id, searchState]);

  const loadNew = async (source) => {
    try {
      setIsLoadingRelatedContent(true);
      setIsLoading(true);

      //SET IN LOCALSTORAGE
      let section = 'ConsultationContentPage';
      let filtersToApply = [];
      let token = isAuthenticated ? await getAccessToken() : '';
      setFiltersLocalStorage({ filtersToApply, section, state, dispatch, source, token, isAuthenticated });
      let contentRequest = {
        cancelToken: source.token,
      };
      if (isAuthenticated) {
        contentRequest.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      let newResult = await axios.get(`${net_api_url}/api/consultation/${id}`, contentRequest);
      const { objectId, objectType } = newResult.data;

      let propertiesHighlight = [objectId, objectType, queryHighlight, ChangeKeywordsExclusions(andKeywords), source];

      let contentHightlighted;
      let highlightCondition = activeSearch || activeReference;
      if (highlightCondition) {
        contentHightlighted = await callHightlightContentAPI(propertiesHighlight);
      }
      let newData = highlightCondition
        ? {
            ...newResult.data,
            titleHighlighted: contentHightlighted ? contentHightlighted.highlightedTitle : newResult.data.title,
            body: contentHightlighted ? contentHightlighted.highlightedHtmlBody : newResult.data.body,
          }
        : newResult.data;

      let { feedback, keywordsFromLists, keywordsListsIds } = await getMatchingKeywordsListsAndFeedback({ objectId });
      newData = { ...newData, feedback, keywordsFromLists, keywordsListsIds };

      const updateResultsOnState = (newData) => {
        let data = {
          ...contentResults,
          [activeResults]: {
            Consultations: newData,
          },
        };
        dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: data });
      };

      let contentObject = Consultations;
      let newSectionObject = contentObject;
      //REMOVE THE ITEM FROM THE LIST AND MARK AS READ
      let removeUnreadItem = updateUnreadItems({ idPosition, contentObject, getIdPosition });
      if (removeUnreadItem) {
        newSectionObject = removeUnreadItem;
      }
      let initialData = {
        ...newSectionObject,
        new: newData,
        currentId: parseInt(id),
      };
      updateResultsOnState(initialData);
      let { descriptionMeta } = newResult.data;
      let title = `${newResult.data.title} - Consultations - PolicyMogul`;
      let hash = '';
      let description = descriptionMeta;
      let contentUrl = `/consultations/${id}/${normalizeString(newResult.data.title)}`;
      addMetaTags({ title, hash, location, dispatch, contentUrl, description });
      setIsLoading(false);

      //CALLS NECESSARY TO DO AFTER THE CONTENT IS SHOWN TO SPEED UP THE PAGE
      let contentRelated = await callContentRelated(objectId, objectType, source);
      let relatedData = {
        ...initialData,
        contentRelated: contentRelated,
      };
      updateResultsOnState(relatedData);
      setIsLoadingRelatedContent(false);

      await callCollectContent({ objectId, objectType, contentUrl });
    } catch (error) {}
  };

  const currentNew = Consultations?.new ?? '';

  return (
    <>
      {isLoading && <SkeletonConsultationContent />}
      {!isLoading && (
        <div ref={containerRef}>
          <div className='row px-0 piece-content-container'>
            <div className={`general-content-column padding-to-nav p-0`}>
              <div ref={contentContainer} />
              <CustomScrollbar
                className={'main-content-scrollbar content-page-scrollbar main-content-section content-container'}
                style={{ height: `${heightContainer}px` }}
                minimalThumbYSize={55}
                maximalThumbYSize={100}
                ref={scrollbarRef}
                onScrollStart={() => {
                  setScrolling(true);
                }}
                onScrollStop={() => {
                  setScrolling(false);
                }}
              >
                <div className='main-update-content-container'>
                  <ConsultationNewContent
                    currentNew={currentNew}
                    contentContainer={contentContainer}
                    scrollbarRef={scrollbarRef}
                    scrolling={scrolling}
                  />
                </div>
              </CustomScrollbar>
            </div>
            <div className='related-content-column show-lg px-0'>
              <ContentRelated
                data={currentNew}
                content={Consultations?.contentRelated}
                isLoadingRelatedContent={isLoadingRelatedContent}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
  //}
};

export default ConsultationContent;
