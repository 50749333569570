import getUrlParam from '../utilities/getUrlParam';

const getUtmParameters = (location) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entries = urlParams.entries();
  const emailAlertsParams = [
    'week-daily-email-alert',
    'weekly-email-alert',
    'immediate-email-alert',
    'digest-combining-all-topics-weekly-email-alert',
    'digest-combining-all-topics-immediate-email-alert',
    'digest-combining-all-topics-week-daily-email-alert',
    'slack-alert',
    'digest-combining-all-topics-embargoed-immediate-override-email-alert',
    'embargoed-immediate-override-email-alert',
    'major-announcement-newsletter',
    'daily-all-events-newsletter',
    'weekly-all-events-newsletter',
    'weekly-westminster-business-events-newsletter',
    'twice-week-daily-email-alert',
    'digest-combining-all-topics-twice-week-daily-email-alert',
  ];
  const emailParam = getUrlParam('utm_source');
  let parameters = [];
  for (const entry of entries) {
    if (entry[0].includes('utm_')) {
      parameters.push(`${entry[0]}=${entry[1]}`);
    }
  }
  if (parameters.length > 0) {
    let cookieExpiresDate = new Date(2038, 0, 1);
    document.cookie = `utm_parameters=${JSON.stringify(parameters)}; expires=${cookieExpiresDate.toUTCString()}; domain=policymogul.com; path=/; Secure`;
  }
  if (emailParam && emailAlertsParams.includes(emailParam)) {
    window.location.assign(
      `/login?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`
    );
  }
};

export default getUtmParameters;
