import { useHeightContainer } from '../Dashboard/utilities/useHeightContainer';
import { useContext, useEffect, useRef, useState } from 'react';
import { SkeletonContent } from '../WritingAssistant/ui/MyContent';
import ReportsCreatePopup from './ReportsCreatePopup';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom-v5-compat';
import { formatGeneralDate } from '../Dashboard/helpers/formatGeneralDate';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import addMetaTags from '../Dashboard/utilities/addMetaTags';
import { store } from '../Store';
import CustomScrollbar from '../Common/CustomScrollbar';
import { useNoTopicsCondition } from '../Dashboard/components/EmptyMessage';
import NoTopicsView from '../Settings/Topics/NoTopicsView';

const ReportList = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { team } = state;

  const [heightContainer, containerRef] = useHeightContainer();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState([]);

  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const { generalApiCall } = useGeneralApiCall();
  const { newAgencyNoTopicsCondition } = useNoTopicsCondition();
  const location = useLocation();

  const getTeamReports = useRef();
  getTeamReports.current = async ({ source }) => {
    try {
      const pathname = '/api/report-definition/team-report-definitions';
      const method = 'get';
      const results = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
      });
      if (results) {
        let finalResults = results.sort((a, b) => {
          return new Date(b.lastEditedAt) - new Date(a.lastEditedAt);
        });
        setResults(finalResults);
        addMetaTags({ title: 'Reports - PolicyMogul', hash: location.hash, location, dispatch });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    init({ data });
    getTeamReports.current({ source });
    return () => {
      source.cancel('Reports list cancelled by the user');
    };
  }, []);

  return (
    <>
      <div className='flex-grow-1'>
        <CustomScrollbar className={'simple-scrollbar'} maximalThumbYSize={100}>
          <div className='main-sticky z-200'>
            <div className={`bg-white monitor-items-bar inbox-items-bar trends-subnav-bar position-relative z-10`}>
              <div className='mx-auto main-content-wrapper px-lg-5 px-3 pt-2'>
                <div className='flex-centered'>
                  <span
                    className='icon-document title-h2 d-inline-block-centered'
                    style={{ marginRight: '18px', marginTop: '-1px' }}
                  />
                  <p className='mb-0'>
                    Create a report with the latest political updates, relevant policymakers and{' '}
                    {agencyUser ? 'real-time analytics for any client or prospect' : 'up-to-date analytics'}
                  </p>
                </div>

                <div className='py-4 d-flex writing-creation-buttons'>
                  <button
                    className='nowrap-item creation-button py-1 px-3 mr-lg-3 py-lg-2 action-button rounded-button px-lg-5 mr-2'
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Create a report{' '}
                    {newAgencyPlan ? <span className='d-none d-lg-inline-block'>for {team?.teamName}</span> : ''}
                  </button>
                </div>
              </div>
            </div>
            <div className='mx-auto main-content-wrapper px-lg-5 px-3 bg-grey py-3 pt-lg-4'>
              <h3 className='title-h4-bold mb-0'>
                {newAgencyPlan ? `Reports for ${team?.teamName}` : `My team's reports`}
              </h3>
            </div>
          </div>

          <div className='mx-auto main-content-wrapper px-lg-5 px-3 d-flex flex-column'>
            <div
              ref={containerRef}
              className={`box-top-rounded writing-my-content ${!isLoading && results?.length === 0 && !newAgencyNoTopicsCondition ? 'reports-editor-container' : ''} ${newAgencyNoTopicsCondition && !isLoading ? '' : 'p-lg-5 p-3'}`}
              style={{ minHeight: `${heightContainer}px` }}
            >
              {isLoading ? (
                <div className='overflow-hidden' style={{ height: `calc(${heightContainer - 2}px - 5em)` }}>
                  <SkeletonContent />
                </div>
              ) : (
                <>
                  {results?.length > 0 ? (
                    <ul className='policy-list px-0 items-list'>
                      {results?.map((item) => {
                        const { id, title, lastEditedAt, authorFullName, icon } = item;
                        return (
                          <Link to={`/reports/${id}`} className={`content-item-link my-2`} key={`item-report-${id}`}>
                            <li className={`p-3`}>
                              <div className='d-flex report-main-list-item'>
                                <span className='report-list-icon text-center'>
                                  {!!icon ? (
                                    <>
                                      {icon?.startsWith('https://') ? (
                                        <img src={icon} alt='Report identifier' />
                                      ) : (
                                        <em-emoji set={'apple'} id={icon} size={'1.25em'} style={{ lineHeight: 1 }} />
                                      )}
                                    </>
                                  ) : (
                                    <span className='icon-document text-main-blue' />
                                  )}
                                </span>
                                <div>
                                  <h4 className='content-item-title mb-0'>{title}</h4>
                                  <p className='text-grey-description mt-1 mb-0 paragraph-p3'>
                                    {authorFullName}{' '}
                                    <span className='heading-date'>
                                      {' '}
                                      <span className='dot' />
                                    </span>{' '}
                                    Last updated on {formatGeneralDate(lastEditedAt)}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </Link>
                        );
                      })}
                    </ul>
                  ) : (
                    <>
                      {newAgencyNoTopicsCondition ? (
                        <NoTopicsView substractBorder={true} />
                      ) : (
                        <div className='empty-state-grid-item text-center'>
                          <p className='title-h4 text-center main-light-text mb-0 text-center'>
                            You haven’t created any reports yet {newAgencyPlan ? `for ${team?.teamName}` : ''}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </CustomScrollbar>
      </div>
      <ReportsCreatePopup isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default ReportList;
