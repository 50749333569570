import React, { useContext, useCallback, useState, useRef, useEffect } from 'react';
import Share from '../components/Share';
import { store } from '../../Store.js';
import normalize from '../utilities/normalizeString';
import { createRoot } from 'react-dom/client';
import ShareParliamentaryContent from './ShareParliamentaryContent.js';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import StakeholderHoverLink from '../components/StakeholderHoverLink';
import useIsMobile from '../utilities/useIsMobile';
import getUrlParam from '../utilities/getUrlParam';
import ContentSummary from '../components/ContentSummary';
import idsWithoutImages from '../key_updates/idsWithoutImages';
import ThumbsDown from '../../Common/ThumbsDown';
import FeedbackOnItem from '../FeedbackItem/FeedbackOnItem';
import TextSelector from '../../Common/TextSelector.js';
const dayjs = require('dayjs');

const ShareButtonRender = (props) => {
  const { currentNew, shareButtonWidget, embed } = props;
  const { id, hansardData, title, questionFor, canonicalUrl, objectId, objectType, edmSignatories } = currentNew;
  let item = {
    id,
    title: hansardData || edmSignatories ? title : questionFor,
    objectID: objectId,
    type: objectType,
  };
  const ShareOptions = {
    item,
    id: item.id,
    title: item.title,
    shareButtonWidget,
    itemShare: embed,
  };
  if (canonicalUrl) {
    ShareOptions.link = `${process.env.REACT_APP_APPURL}${canonicalUrl}`;
  }
  return <Share {...ShareOptions} />;
};

const ParliamentaryNewContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, contentResults, activeResults } = state;

  const { ParliamentaryRecord } = contentResults[activeResults];
  const { activeSearch } = search;
  const {
    currentNew,
    scrollBarRef,
    shareButtonWidget,
    contributionId,
    contentContainer,
    isScrolling,
    setUpdatingContribution,
    hideSummary,
    reportWidget,
    embed,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { type } = params;

  const scottishOfficialReport = window.location.pathname.includes('scottish-official-report');
  const welshOfficialReport = window.location.pathname.includes('welsh-official-report');
  const seneddCommitteeTranscriptReport = window.location.pathname.includes('senedd-committee-transcript');

  const isMobile = useIsMobile();
  const [showFixedTitle, setShowFixedTitle] = useState(window.location.hash.includes('contribution-'));
  const createMarkup = (body) => {
    return { __html: body };
  };
  const itemClass = () => {
    switch (currentNew.hansardData.type.toLowerCase()) {
      case 'commons':
        return 'legislation-commons';
      case 'lords':
        return 'legislation-lords';
      default:
        return 'legislation-grey';
    }
  };

  const renderTitle = () => {
    let title;
    if (
      (!!currentNew.titleHighlighted || !!currentNew.questionForHighlighted) &&
      (activeSearch || shareButtonWidget || getUrlParam('search'))
    ) {
      title =
        currentNew.questionForHighlighted && (currentNew.questionFor || currentNew.questionText)
          ? currentNew.questionForHighlighted
          : currentNew.titleHighlighted;
    } else {
      title = currentNew.title;
    }
    return title;
  };

  const adjustmentWelshAnchors = useRef();
  adjustmentWelshAnchors.current = (node) => {
    if (type === 'welsh-official-report') {
      let elements = document.querySelectorAll('.contributionText');
      for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        let id = element.getAttribute('id');
        if (id && id.startsWith('A7')) {
          element.classList.add('adjustment-anchor-welsh');
        }
      }
    }
  };

  const contributionContainer = useCallback((node) => {
    if (node !== null) {
      const createShareButtons = () => {
        let elements = document.querySelectorAll('.debate-item-contributiondebateitem');
        if (elements.length > 1) {
          for (let i = 0; i < elements.length; i++) {
            let item = elements[i];
            let children = item.children[0];
            let shareButton = document.createElement('div');
            const el = createRoot(shareButton);
            el.render(
              <ShareParliamentaryContent
                url={`${window.location.origin}${currentNew.canonicalUrl}?utm_${
                  shareButtonWidget ? 'campaign' : 'source'
                }=share${shareButtonWidget ? '-widget' : ''}-button#contribution-${children.dataset.contributionId}`}
                title={children.dataset.shareTitle}
              />
            );
            item.appendChild(shareButton);
          }
        }
      };
      createShareButtons();
      adjustmentWelshAnchors.current();
    }
  }, []);

  const highlightNextSiblings = (elem, className) => {
    let elems = Array.from(elem.parentElement.children);
    let elemIndex = elems.findIndex((item) => item.id === elem.id);
    let slicedElems = elems.slice(elemIndex + 1);
    let nextElemIndex = slicedElems.findIndex((item, index) => (!!item.id || item.tagName === 'HR') && index !== 0);
    let finalElems = slicedElems.slice(0, nextElemIndex);
    finalElems.forEach((item) => {
      item.classList.add(className);
    });
  };

  const updateExtract = useRef();
  updateExtract.current = () => {
    let hash = window.location.hash.split('~');
    if ((hash[0].includes('contribution') || !!contributionId) && !isScrolling) {
      //Reset class
      let className =
        scottishOfficialReport || welshOfficialReport || seneddCommitteeTranscriptReport
          ? 'scottish-welsh-debate-item-contributiondebateitem-highlighted'
          : 'debate-item-contributiondebateitem-highlighted';
      let elements = document.getElementsByClassName(`${className}`);
      if (elements.length) {
        Array.from(elements).forEach((element) => element.classList.remove(className));
      }

      let id = !!contributionId
        ? `contribution-${contributionId}`
        : scottishOfficialReport || welshOfficialReport || seneddCommitteeTranscriptReport
          ? hash[0].replace('#', '').replace('contribution-', '')
          : hash[0].replace('#', '');
      let idElement = document.getElementById(id);
      if (idElement) {
        let item =
          scottishOfficialReport || welshOfficialReport || seneddCommitteeTranscriptReport
            ? document.getElementById(id)
            : document.getElementById(id).parentElement;

        if (scottishOfficialReport) {
          highlightNextSiblings(idElement, className);
        } else {
          item.classList.add(className);
        }
        //AE: It's neccessary the setTimeout function to wait until the ref is updated correctly. It seems with 1 as the time it works ok, but we need to see if in any case it needs more time.
        setTimeout(() => {
          let position =
            scottishOfficialReport || welshOfficialReport || seneddCommitteeTranscriptReport
              ? item.offsetTop + (alreadyMarkedFeedback ? 180 : 100)
              : item.offsetTop - 60;
          if (scrollBarRef && scrollBarRef.current) {
            if (setUpdatingContribution) {
              setUpdatingContribution(true);
            }
            scrollBarRef.current.scrollTop(position);
          }
        }, 1);
        setTimeout(() => {
          if (setUpdatingContribution) {
            setUpdatingContribution(false);
          }
        }, 150);
      }
    }
  };

  useEffect(() => {
    updateExtract.current();
  }, [location.hash]);

  const openStakeholderFromWidget = (e) => {
    if (shareButtonWidget) {
      e.preventDefault();
      window.open(e.target.href);
    }
  };

  const parliamentaryContentContainer = useCallback((node) => {
    if (node !== null && !isMobile) {
      const createStakeholdersComponent = () => {
        if (welshOfficialReport) {
          let elementsWithTimestamp = document.querySelectorAll('.memberInfo');
          for (let i = 0; i < elementsWithTimestamp.length; i++) {
            let item = elementsWithTimestamp[i];
            const timeStamp = item.querySelector('.time');
            if (timeStamp) {
              timeStamp.parentNode.removeChild(timeStamp);
            }
          }
        }

        let elements = document.querySelectorAll('.news-content-in a');
        for (let i = 0; i < elements.length; i++) {
          let item = elements[i];
          if (!!item.href) {
            let urlItem = new URL(item.href);
            let pathname = urlItem.pathname;
            if (
              (!item.classList?.contains('edm-link') && pathname && pathname.startsWith('/stakeholders')) ||
              pathname.startsWith('/monitor/stakeholders') ||
              pathname.startsWith('/influence/stakeholders') ||
              pathname.startsWith('/stakeholder-mapping')
            ) {
              const timeStamp = item.querySelector('.time');
              if (timeStamp) {
                timeStamp.parentNode.removeChild(timeStamp);
              }
              const stakeholderLink = document.createElement('span');
              const el = createRoot(stakeholderLink);
              el.render(
                <StakeholderHoverLink
                  contactId={pathname.match(/[0-9]+/)[0]}
                  name={item.textContent}
                  contentContainer={contentContainer}
                  navigate={navigate}
                />
              );
              item.parentNode.replaceChild(stakeholderLink, item);
            }
          }
        }
      };
      createStakeholdersComponent();
    }
  }, []);

  const titleSection = useRef(null);
  let observer = useRef();
  let createObserver = useRef();
  createObserver.current = () => {
    if (!!window.IntersectionObserver) {
      observer.current = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (titleSection.current !== null) {
              setShowFixedTitle(!entry.isIntersecting);
            }
          });
        },
        { rootMargin: '0px 0px 0px 0px' }
      );
      observer.current.observe(titleSection.current);
    }
  };

  const createStyles = () => {
    if (!!contentContainer.current) {
      const { width, left, top } = contentContainer.current.getBoundingClientRect();
      let borderValue = 1;
      let styles = {
        position: 'fixed',
        top: top,
        left: left + borderValue,
        width: width - borderValue * 2,
      };
      return styles;
    }
  };
  const [titleStyleRules, setTitleStyleRules] = useState(createStyles());
  const updateStyles = () => {
    setTitleStyleRules(createStyles());
  };

  useEffect(() => {
    if (createObserver.current) {
      createObserver.current();
    }
    window.addEventListener('resize', updateStyles);
    return () => {
      window.removeEventListener('resize', updateStyles);
      if (!!observer.current && titleSection.current !== null) {
        observer.current.unobserve(titleSection.current);
      }
    };
  }, []);

  const alreadyMarkedFeedback = !!currentNew?.feedback;
  const index = ParliamentaryRecord ? ParliamentaryRecord?.hits?.findIndex((item) => item.id === currentNew.id) : null;
  const ThumbsDownProperties = {
    keywordsFromLists: currentNew?.keywordsFromLists,
    item: { objectID: currentNew?.objectId },
    nameSection: 'ParliamentaryRecord',
    show: !shareButtonWidget && !!currentNew?.keywordsFromLists,
    embedFeed: embed,
    contentPage: true,
    alreadyMarked: alreadyMarkedFeedback,
    thick: true,
  };

  return (
    <>
      <div ref={titleSection}>
        <div
          className={`section-title pb-2 ${reportWidget ? 'content-title-in-widget' : 'content-title-with-summary'} content-border-title`}
        >
          <h3 className='main-title news-title bg-white ' dangerouslySetInnerHTML={createMarkup(renderTitle())} />
          <div className='row mx-0 justify-content-between align-items-center'>
            <p className='heading-date mb-0'>
              {currentNew.hansardData && (
                <>
                  <span className={`${itemClass()}`}>
                    {currentNew.hansardData.type.charAt(0).toUpperCase() + currentNew.hansardData.type.slice(1)}
                  </span>
                  <span className='dot'></span>
                  {currentNew.type}
                  {currentNew.type !== '' && <span className='dot'></span>}
                  {dayjs(currentNew.hansardData.date).format('DD MMMM YYYY')}
                </>
              )}
              {(currentNew.questionFor || currentNew.questionText) && (
                <>
                  Tabled on {dayjs(currentNew.questionDate).format('DD MMMM YYYY')}
                  {!!currentNew.sourceUrl && (
                    <>
                      <span className='dot'></span>
                      <a
                        className='legislation-source mb-0'
                        href={currentNew.sourceUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Go to source
                      </a>
                    </>
                  )}
                </>
              )}
              {currentNew.tabledOn && (
                <span className='heading-date mb-0'>
                  EDM {currentNew.number}: Tabled on {dayjs(currentNew.tabledOn).format('DD MMMM YYYY')}
                </span>
              )}
            </p>
            <div className='flex-centered'>
              <ThumbsDown {...ThumbsDownProperties} />
              <ShareButtonRender currentNew={currentNew} shareButtonWidget={shareButtonWidget} embed={embed} />
            </div>
          </div>
          {currentNew.tabledOn && <p className='heading-date mb-2'>Tabled in the {currentNew.session} session</p>}
        </div>
      </div>

      {alreadyMarkedFeedback && (
        <div className='debate-item-contributiondebateitem-padding px-0 pb-0'>
          <FeedbackOnItem
            contentPage={true}
            item={{ objectID: currentNew?.objectId }}
            feedback={currentNew?.feedback}
            keywordsFromLists={currentNew?.keywordsFromLists}
            index={index}
            customClass={'px-3 px-lg-4 content-border-title'}
          />
        </div>
      )}
      <ContentSummary content={currentNew} hide={hideSummary} />
      <div className='pb-3 pb-lg-4 pb-lg-0 specific-content-container'>
        {/* FIXED TITLE */}
        <div
          className={`content-item-scrolled-title main-sticky section-title bg-white parliamentary-scrolled-title ${
            showFixedTitle && !reportWidget ? 'item-scrolled-title-visible' : ''
          }`}
          style={titleStyleRules}
        >
          <div className='d-flex justify-content-between scrolled-title-container'>
            <h3 className='main-title news-title bg-white' dangerouslySetInnerHTML={createMarkup(renderTitle())} />
            <ThumbsDown {...ThumbsDownProperties} />
            <ShareButtonRender currentNew={currentNew} shareButtonWidget={shareButtonWidget} />
          </div>
        </div>
        {/* END FIXED TITLE */}
        <TextSelector currentNew={currentNew} scrollbarRef={scrollBarRef} scrolling={isScrolling}>
          <div
            className={`py-3 news-content-in parliamentary-content-in context ${
              type === 'hansard-content' ? 'parliamentary-hansard-content' : ''
            } ${type === 'senedd-committee-transcript' ? 'parliamentary-senedd-committee-transcript-content' : ''}${
              type === 'welsh-official-report' ? 'welsh-official-report-content' : ''
            }`}
            ref={parliamentaryContentContainer}
          >
            {currentNew?.extract ? (
              <>
                <div className='debate-item-contributiondebateitem'>
                  <div
                    className='explanatory-container'
                    dangerouslySetInnerHTML={createMarkup(currentNew.extract)}
                    ref={contributionContainer}
                  />
                </div>
              </>
            ) : (
              <>
                {currentNew.hansardData && (
                  <div
                    className='explanatory-container'
                    dangerouslySetInnerHTML={createMarkup(currentNew.body)}
                    ref={contributionContainer}
                  />
                )}
                {currentNew.content && (
                  <div className='debate-item-contributiondebateitem'>
                    <div
                      className='explanatory-container'
                      dangerouslySetInnerHTML={createMarkup(currentNew.content)}
                      ref={contributionContainer}
                    />
                  </div>
                )}
                {currentNew?.edmSignatories && (
                  <>
                    <div className='debate-item-contributiondebateitem'>
                      <h3 className='mb-0'>Primary Sponsor</h3>
                      <p className='mb-3'>
                        {!!currentNew.primarySponsorContactId ? (
                          <Link to={`${currentNew?.primarySponsorUrl}${location.search}`}>
                            {' '}
                            {currentNew.primarySponsorName}
                          </Link>
                        ) : (
                          <span> {currentNew.primarySponsorName}</span>
                        )}
                      </p>

                      <h3 className='mb-3'>Motion text</h3>
                      <div className='explanatory-container' dangerouslySetInnerHTML={createMarkup(currentNew.body)} />

                      {currentNew?.edmSignatories?.length > 0 && (
                        <>
                          <h3 className='mt-4 mb-3'>Signatures ({currentNew?.edmSignatories?.length})</h3>
                          <div className='row mx-0'>
                            {currentNew?.edmSignatories?.map((item, index) => {
                              const { url, signatoryName, party, contactId, imageId } = item;
                              return (
                                <div
                                  className={`col-lg-8 d-flex px-0 p${index % 2 === 0 ? 'r' : 'l'}-lg-1 mb-3`}
                                  key={`item-${index}-${contactId}`}
                                >
                                  <Link
                                    to={url}
                                    className='align-items-start d-flex rounded d-block h-100 edm-link border box-shadow-hover p-3 text-main-blue flex-grow-1 mr-3'
                                  >
                                    <div
                                      className={`stakeholder-profile-image member-picture member-${
                                        !!party ? party.toLowerCase() : ''
                                      }-picture`}
                                      style={{
                                        backgroundImage:
                                          imageId !== 0 && imageId !== null && !idsWithoutImages.includes(imageId)
                                            ? `url('https://www.parliamenttoday.com/image.php?imageid=${imageId}&ml=1')`
                                            : `url('${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png')`,
                                      }}
                                    />
                                    <div className='flex-grow-1 pl-2'>
                                      <p className='title-h5-bold text-main-blue mb-1'>{signatoryName}</p>
                                      {party !== null && <p className='text-main-blue mb-0'>{party}</p>}
                                    </div>
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                {(currentNew.questionFor || currentNew.questionText) && (
                  <div className='px-4'>
                    <h3>Question</h3>
                    <p className='question-answer-by'>
                      Asked by
                      {!!currentNew.questionByContactId && (
                        <Link
                          to={`/stakeholders/${currentNew.questionByContactId}/${normalize(currentNew.questionBy)}${
                            shareButtonWidget ? '' : location.search
                          }`}
                          onClick={openStakeholderFromWidget}
                        >
                          {' '}
                          {currentNew.questionBy}
                        </Link>
                      )}
                      {!currentNew.questionByContactId && <span> {currentNew.questionBy}</span>}
                    </p>

                    <p dangerouslySetInnerHTML={createMarkup(currentNew.questionText)}></p>
                    {currentNew.questionHasAnswer && (
                      <>
                        <h3>Answer</h3>
                        <p className='question-answer-by'>
                          Answered by
                          {!!currentNew.answerByContactId && (
                            <Link
                              to={`/stakeholders/${currentNew.answerByContactId}/${normalize(currentNew.answerBy)}${
                                shareButtonWidget ? '' : location.search
                              }`}
                              onClick={openStakeholderFromWidget}
                            >
                              {' '}
                              {currentNew.answerBy}
                            </Link>
                          )}
                          {!currentNew.answerByContactId && <span> {currentNew.answerBy}</span>}
                          <span className='question-date'>
                            {' '}
                            on {dayjs(currentNew.answerDate).format('DD MMMM YYYY')}
                          </span>
                        </p>
                        <div dangerouslySetInnerHTML={createMarkup(currentNew.answerText)}></div>
                      </>
                    )}
                    {!currentNew.questionHasAnswer && (
                      <p className='px-3 py-2 no-answer-box'>Currently awaiting answer</p>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </TextSelector>
      </div>
    </>
  );
};

export default ParliamentaryNewContent;
