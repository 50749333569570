import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import createNotification from '../Settings/Utilities/CreateNotification';
import AdminTopicPage from './ui/AdminTopicPage';
import UserTeamTopics from './ui/UserTeamTopics';
import SortArray from '../Dashboard/utilities/SortArray';
import Organization from './ui/Organization';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom-v5-compat';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import { AdminStore } from './AdminState';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import { useAdminAgencyUser } from './ui/UserSideNav';

const TeamTopicPage = () => {
  const adminState = useContext(AdminStore);
  const { state, dispatch } = adminState;
  const { organisation } = state ?? {};

  const [isLoading, setIsLoading] = useState(true);
  const [teamTopics, setTeamTopics] = useState(
    organisation?.teams?.flatMap((item) => item?.keywordLists)?.sort(SortArray('name'))
  );
  const [teamMembers, setTeamMembers] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const teamIdParameter = getUrlParam('teamId');

  const { generalApiCall } = useGeneralApiCall();
  const { newAgencyPlan } = useAdminAgencyUser();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadInitialInformation(source);
    return () => {
      source.cancel('Team topics cancelled by the user');
    };
  }, []);

  let loadInitialInformation = async (source) => {
    try {
      if (id !== undefined) {
        let teamMembers = await loadTeamMembers(source);
        if (!!teamMembers) {
          setTeamMembers(teamMembers);
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };

  const loadTeamMembers = async (source) => {
    let method = 'post';
    let pathname = '/api/admin/list-users';
    let requestProperties = {
      organisationId: parseInt(id),
      pageNumber: 1,
      pageSize: 100000,
    };
    let results = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
    return results;
  };

  const newTopicProperties = () => {
    return {
      id: '',
      name: '',
      keywords: [],
      andKeywords: [],
      keywordExclusions: [],
      ownerUserId: teamMembers.find((item) => item.teamRole === 'Owner')?.id,
      ptHandPickedContentKeywords: [],
      description: null,
    };
  };

  const returnUrl = `/admin/organisation/${id}/${newAgencyPlan ? 'clients' : 'topics'}`;

  const createUpdateKeywordList = async ({ topic, e }) => {
    let {
      name,
      keywords,
      andKeywords,
      keywordExclusions,
      ownerUserId,
      ptHandPickedContentKeywords,
      id,
      description,
      countries,
    } = topic;
    if (name === '') {
      return createNotification('danger', 'Please give your topic a name');
    } else if (keywords.length === 0) {
      return createNotification('danger', 'Please enter at least one keyword');
    } else {
      let el = e.target;
      el.disabled = true;
      let pathname = `/api/admin/${!!id ? 'update' : 'create'}-keyword-list`;

      let requestProperties = {
        name,
        keywords,
        andKeywords,
        keywordExclusions,
        ownerUserId,
        ptHandPickedContentKeywords,
        description: !description || description.trim() === '' ? null : description,
        countries,
      };
      if (!!id) {
        requestProperties = { ...requestProperties, id };
      }
      if (teamIdParameter) {
        requestProperties = { ...requestProperties, teamId: parseInt(teamIdParameter) };
      }

      try {
        let topicResult = await generalApiCall({
          pathname,
          method: 'post',
          requestProperties,
          needsAuthentication: true,
          requestSource: source,
        });
        if (!!topicResult) {
          navigate(returnUrl);
          let newTeamTopics = [...teamTopics];
          let topicPosition = !!id ? newTeamTopics.findIndex((topic) => topic.id === id) : teamTopics.length;
          newTeamTopics[topicPosition] = topicResult;
          setTeamTopics(newTeamTopics);
          updateAdminState({ topicId: id, result: topicResult });
          createNotification('success', `Topic ${id ? 'updated' : 'created'} successfully`);
        }
      } catch (error) {
        let errorMessage = 'There was an error completing your operation. Please reload the page.';
        if (error?.response?.data) {
          errorMessage = error.response.data;
        }
        createNotification('danger', `${errorMessage}`);
      }

      el.disabled = false;
    }
  };

  const deleteKeywordList = async ({ topicId, e }) => {
    try {
      let el = e.target;
      el.disabled = true;
      let method = 'delete';
      let pathname = `/api/admin/delete-keyword-list/${topicId}`;
      let requestProperties = {};
      let topicDeleted = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        requestProperties,
        requestSource: source,
      });
      if (topicDeleted) {
        navigate(returnUrl);
        let newTeamTopics = [...teamTopics];
        let topicPosition = newTeamTopics.findIndex((topic) => topic.id === topicId);
        newTeamTopics.splice(topicPosition, 1);
        setTeamTopics(newTeamTopics);
        updateAdminState({ topicId, action: 'delete' });
        createNotification('success', `Topic deleted successfully`);
      }
      el.disabled = false;
    } catch (error) {}
  };

  const updateAdminState = ({ topicId, result, action = 'add' }) => {
    let newTeams = [...organisation?.teams];
    const teamIdParameterPosition = teamIdParameter
      ? newTeams?.findIndex((item) => (item?.teamId ?? item?.id) === parseInt(teamIdParameter))
      : null;
    const teamPosition = !!teamIdParameterPosition && teamIdParameterPosition >= 0 ? teamIdParameterPosition : 0;
    let newTeam = { ...newTeams?.[teamPosition] };
    let newTeamLists = newTeam?.keywordLists ?? newTeam?.myKeywordLists ?? [];
    let topicPosition = !!topicId ? newTeamLists.findIndex((topic) => topic.id === topicId) : newTeamLists?.length;
    if (action === 'delete') {
      newTeamLists?.splice(topicPosition, 1);
    } else {
      newTeamLists[topicPosition] = result;
    }
    newTeam = { ...newTeam, keywordLists: newTeamLists, myKeywordLists: newTeamLists, teamMemberKeywordLists: [] };
    newTeams[teamPosition] = newTeam;
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'organisation',
      value: { ...organisation, teams: newTeams },
    });
  };
  //Get current topic
  const getCurrentTopic = () => {
    let topicId = params.topicId?.split('?')?.[0];
    if (topicId === 'new-topic') {
      return newTopicProperties();
    } else {
      const topic = JSON.parse(JSON.stringify(teamTopics)).find((item) => item.id === parseInt(topicId));
      if (topic) {
        return topic;
      } else {
        return null;
      }
    }
  };

  return (
    <>
      {isLoading && <p className='pt-5'>Loading...</p>}
      {!isLoading && (
        <Routes>
          <Route
            exact
            path={`/`}
            element={<UserTeamTopics teamTopics={teamTopics} organisationId={id} setTeamTopics={setTeamTopics} />}
          ></Route>
          <Route
            exact
            path={`/:topicId/organisation`}
            element={<Organization team={null} setTeam={() => {}} getCurrentTopic={getCurrentTopic} clientPage />}
          ></Route>
          <Route
            exact
            path={`/:topicId`}
            element={
              !getCurrentTopic() ? (
                <Navigate to={`/admin/organisation/${id}/${newAgencyPlan ? 'clients' : 'topics'}`} />
              ) : (
                <AdminTopicPage
                  teamMembers={teamMembers}
                  deleteKeywordList={deleteKeywordList}
                  getCurrentTopic={getCurrentTopic}
                  createUpdateKeywordList={createUpdateKeywordList}
                />
              )
            }
          ></Route>
        </Routes>
      )}
    </>
  );
};

export default TeamTopicPage;
