import TopicKeywords from './TopicKeywords';
import { useContext, useState } from 'react';
import { store } from '../../Store';

const KeywordsAndAdvancedSearchComponent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, activePlan, listPosition, team } = state;

  const {
    openStates,
    setOpenStates,
    customTopic,
    customHandleChange,
    tooltipTarget,
    enforceInitialFocus,
    showSuggestion,
    setShowSuggestion,
  } = props;

  const currentKeywordCategory = customTopic ?? keywordsLists[listPosition];
  const { enableAdvancedSearch } = activePlan;
  const { myself } = team ?? {};
  const { isMyList } = currentKeywordCategory ?? {};

  const currentList = listPosition === null ? {} : keywordsLists[listPosition];
  const { keywordExclusions, andKeywords } = customTopic ?? currentList;

  const [openAdvancedOptions, setOpenAdvancedOptions] = useState(false);

  const advancedOptionsFilled = () => {
    return andKeywords.length > 0 || keywordExclusions.length > 0;
  };

  return (
    <>
      <div className='topics-search-items'>
        {openAdvancedOptions && (
          <div className='px-4 pt-3 keywords-specific-category-title'>
            <p className='keywords-rule-title pl-1'>
              <CategoryTitle type={'keywords'} tooltipTarget={tooltipTarget} />
            </p>
          </div>
        )}

        <TopicKeywords
          listPosition={listPosition}
          parameter={'keywords'}
          customTopic={customTopic}
          customHandleChange={customHandleChange}
          enforceInitialFocus={enforceInitialFocus}
          openAdvancedOptions={openAdvancedOptions}
          showSuggestion={showSuggestion}
          setShowSuggestion={setShowSuggestion}
        />
        {!openAdvancedOptions && !advancedOptionsFilled() && (
          <div
            className='px-3 border-top py-2 pointer advanced-options-button'
            data-tooltip-content={
              !enableAdvancedSearch
                ? 'Advanced search is available on Pro, Mogul or Enterprise, and allows you to hone in on relevant content and avoid information overload'
                : ''
            }
            data-tooltip-id='email-alert-tooltip'
            data-tooltip-float={true}
            data-tooltip-class-name='pricing-feature-tooltip topic-category-tooltip'
            onClick={() => {
              if (enableAdvancedSearch && (isMyList || myself?.role !== 'Member' || !!customTopic)) {
                setOpenAdvancedOptions(true);
              }
            }}
          >
            <p className='mb-0'>See advanced options</p>
            {!enableAdvancedSearch && <AdvancedPadlock />}
          </div>
        )}
        {(openAdvancedOptions || advancedOptionsFilled()) && (
          <>
            <p
              className={`keywords-rule-title add-rule-title pt-2 ${openStates.additional ? '' : 'pb-2'} px-4 ${
                !openStates.additional ? 'keywords-rule-title-closed' : ''
              }
              ${!openStates.additional && openStates.exclusions ? 'keywords-rule-and-closed' : ''}
            `}
              onClick={() => {
                if (enableAdvancedSearch && (isMyList || myself?.role !== 'Member')) {
                  setOpenStates({
                    ...openStates,
                    additional: andKeywords.length === 0 ? !openStates.additional : true,
                  });
                }
              }}
            >
              <CategoryTitle type={'andKeywords'} tooltipTarget={tooltipTarget} />
            </p>

            {openStates.additional && (
              <TopicKeywords
                listPosition={listPosition}
                parameter='andKeywords'
                customTopic={customTopic}
                customHandleChange={customHandleChange}
              />
            )}

            <p
              className={`keywords-rule-title keywords-exclusions-title pt-2 ${
                openStates.exclusions ? '' : 'pb-2'
              } px-4 ${!openStates.exclusions ? 'keywords-rule-title-closed' : ''}`}
              onClick={() => {
                if (enableAdvancedSearch && (isMyList || myself?.role !== 'Member')) {
                  setOpenStates({
                    ...openStates,
                    exclusions: keywordExclusions.length === 0 ? !openStates.exclusions : true,
                  });
                }
              }}
            >
              <CategoryTitle type={'keywordsExclusions'} tooltipTarget={tooltipTarget} />
            </p>

            {openStates.exclusions && (
              <TopicKeywords
                listPosition={listPosition}
                parameter={'keywordExclusions'}
                customTopic={customTopic}
                customHandleChange={customHandleChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const CategoryTitle = ({ type, tooltipTarget = 'email-alert-tooltip' }) => {
  const createStrongText = () => {
    switch (type) {
      case 'keywords':
        return 'Any of';
      case 'andKeywords':
        return '+ And also';
      case 'keywordsExclusions':
        return '+ But not';
      default:
        return '';
    }
  };
  const createComplementaryText = () => {
    switch (type) {
      case 'keywords':
        return 'these keywords';
      default:
        return 'any of these keywords';
    }
  };
  const creatToolTipText = () => {
    switch (type) {
      case 'keywords':
        return 'Make sure you want at least one of the words or phrases in this section to appear in each piece of content';
      case 'andKeywords':
        return 'Make sure you want at least one of the words or phrases in this section to appear in each piece of content, in addition to at least one keyword from the previous section.';
      case 'keywordsExclusions':
        return 'You will not receive content that contains any of these keywords';
      default:
        return '';
    }
  };
  return (
    <>
      <span className='text-uppercase'>
        <strong>{createStrongText()}</strong> {createComplementaryText()}
      </span>
      <span
        className='pricing-feature-more'
        data-tooltip-content={creatToolTipText()}
        data-tooltip-id={tooltipTarget}
        data-tooltip-place='right'
        data-tooltip-class-name='pricing-feature-tooltip topic-category-tooltip'
        data-effect={'solid'}
      >
        More information
      </span>
    </>
  );
};

const AdvancedPadlock = () => {
  return (
    <img src={`${process.env.REACT_APP_CDNURL}/images/padlock.svg`} alt='padlock' className='padlock-title-item' />
  );
};

export default KeywordsAndAdvancedSearchComponent;
