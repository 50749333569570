import MicroModal from 'micromodal';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom-v5-compat';
import { store } from '../../Store.js';
import TopicName from './TopicName.js';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import TopicSaveButton from './TopicSaveButton.js';
import TopicDeleteButton from './TopicDeleteButton.js';
import TopicDeletePopUp from './TopicDeletePopUp.js';
import TopicDiscardPopUp from './TopicDiscardPopUp.js';
import useIsMobile from '../../Dashboard/utilities/useIsMobile.js';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer.js';
import CreateEmailAlert from './CreateEmailAlert.js';
import TopicEmailAlerts from './TopicEmailAlerts';
import callBillingPortal from '../../Dashboard/apiCalls/callBillingPortal.js';
import axios from 'axios';
import CountriesPopupContainer, { CountriesPopup } from './CountriesPopup.js';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord.js';
import HideTopicCheckbox from './HideTopicCheckbox.js';
import KeywordsAndAdvancedSearchComponent from './KeywordsAndAdvancedSearchComponent.js';
import CustomScrollbar from '../../Common/CustomScrollbar.js';
import getUrlParam from '../../Dashboard/utilities/getUrlParam.js';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken.js';

const TopicComponent = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { keywordsLists, activeSubscriptions, InitialKeywordsState, listPosition, team, IsSavingKeywordList, clients } =
    state;
  const { myself } = team ?? {};
  const currentKeywordCategory = keywordsLists[listPosition];

  const { isMyList, countries } = currentKeywordCategory ?? {};
  const { maxListsPerTeam, allowImmediateAlerts, allowWeekdailyAlerts, allowWeeklyAlerts, isPaidFor } =
    activeSubscriptions;
  const allowAlerts = allowImmediateAlerts && allowWeekdailyAlerts && allowWeeklyAlerts;

  const params = useParams();
  const { list } = params;
  const newList = list === 'new-topic' || list === 'new-client';
  const teamIdParameter = getUrlParam('teamId');
  const emailAlerts = teamIdParameter
    ? state?.emailAlerts?.filter((item) => item?.teamId === parseInt(teamIdParameter))
    : state?.emailAlerts;
  const navigate = useNavigate();
  const location = useLocation();

  const [targetLink, setTargetLink] = useState('');
  const [isOpenEditEmailAlert, setIsOpenEditEmailAlert] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [emailAlertId, setEmailAlertId] = useState('');
  const [showRegionsPopup, setShowRegionsPopup] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(newList ? true : false);
  const [openStates, setOpenStates] = useState({
    additional: false,
    exclusions: false,
  });

  const pricingUrl = `/pricing?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const { topicOrClientWord, transformTopicOrClientWord, agencyUser, newAgencyPlan } = useTopicOrClientWord();

  const { getAccessToken } = useGetAccessToken();

  useEffect(() => {
    let listPosition = null;
    let teamId = !!teamIdParameter ? parseInt(teamIdParameter) : team?.teamId;
    let listsToCompare = newAgencyPlan ? keywordsLists?.filter((item) => item?.teamId === teamId) : keywordsLists;
    if (newList && (maxListsPerTeam === null || listsToCompare.length < maxListsPerTeam)) {
      listPosition = listsToCompare.length;
    }
    keywordsLists.forEach((item, index) => {
      if (item.id === parseInt(list)) {
        listPosition = index;
      }
    });
    if (listPosition === null) {
      return navigate(
        newAgencyPlan ? `/settings/clients` : `/settings/${transformTopicOrClientWord({ plural: true })}`
      );
    } else {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'listPosition',
        value: listPosition,
      });
    }

    //OPEN COMPONENT WHEN THERE ARE EXCLUSIONS OR ANDKEYWORDS
    if (!newList) {
      setOpenStates({
        exclusions: keywordsLists[listPosition].keywordExclusions.length > 0,
        additional: keywordsLists[listPosition].andKeywords.length > 0,
      });
    }

    if (newList) {
      let newList = {
        id: '',
        name: '',
        keywords: [],
        hasEmailAlertSettings: false,
        keywordExclusions: [],
        andKeywords: [],
        isHandpicked: false,
        isMyList: true,
        index: keywordsLists.length,
        description: '',
        countries: 'UK, Scotland, Wales, NorthernIreland',
        teamId: team?.teamId,
      };
      let keywordsListsToRender = keywordsLists.slice();
      let newPosition = keywordsLists.length;
      keywordsListsToRender.push(newList);
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'keywordsLists',
        value: keywordsListsToRender,
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'listPosition',
        value: newPosition,
      });
    }
    setIsReady(true);
    if (isMobile) {
      document.getElementsByTagName('body')[0].style.overflow = 'visible';
    }
    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      resetEmailAlerts();
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'listPosition',
        value: null,
      });
    };
  }, []);

  const resetEmailAlerts = () => {
    if (newList) {
      let emailAlertsFiltered = emailAlerts?.filter((item) => !item.id.toString().startsWith('temp'));
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'emailAlerts',
        value: emailAlertsFiltered,
      });
    }
  };

  const currentList = listPosition === null ? {} : keywordsLists[listPosition];
  const { keywords, id, name } = currentList;

  const topicsComponentContainer = useRef(null);

  const handleClickOutside = (event) => {
    if (topicsComponentContainer.current && !topicsComponentContainer.current.contains(event.target)) {
      let initialTopic = InitialKeywordsState[listPosition];
      let currentTopic = keywordsLists[listPosition];
      let haveChanges;
      if (id === '') {
        haveChanges = name.length > 0 || keywords.length > 0 ? true : false;
      } else {
        haveChanges = JSON.stringify(initialTopic) === JSON.stringify(currentTopic) ? false : true;
      }
      if (haveChanges && event.target.href !== undefined) {
        let url = new URL(event.target.href);
        setTargetLink(`${url.pathname}`);
        MicroModal.show('topic-discard-popup', {
          awaitCloseAnimation: true,
          disableFocus: true,
        });
      }
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [topicsComponentContainer, state]);

  const discardFunction = () => {
    if (targetLink.includes(`/settings/${transformTopicOrClientWord({ plural: true })}`)) {
      MicroModal.close('topic-discard-popup');
      if (id === '') {
        let keywordsListsToRender = keywordsLists.slice();
        keywordsListsToRender.splice(keywordsLists.length - 1, 1);
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'keywordsLists',
          value: keywordsListsToRender,
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'listPosition',
          value: null,
        });
        navigate(`/settings/${transformTopicOrClientWord({ plural: true })}`);
      } else {
        let keywordsListsToRender = keywordsLists.slice();
        keywordsListsToRender[listPosition] = InitialKeywordsState[listPosition];
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'keywordsLists',
          value: keywordsListsToRender,
        });
        navigate(`/settings/${transformTopicOrClientWord({ plural: true })}`);
      }
    } else {
      MicroModal.close('topic-discard-popup');
      setTimeout(() => {
        navigate(targetLink);
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'listPosition',
          value: null,
        });
      }, 200);
    }
  };

  const isMobile = useIsMobile();

  const [heightContainer, containerRef] = useHeightContainer();

  const createEmailAlertsForThisTopic = () => {
    if (currentKeywordCategory.id === '') {
      return emailAlerts.filter((item) => item.id.toString().startsWith('temp'));
    } else {
      return emailAlerts.filter(
        (item) =>
          (item.digestCombiningAllTopics === true || item.keywordListIds.includes(currentKeywordCategory.id)) &&
          (!newAgencyPlan || (newAgencyPlan && currentKeywordCategory?.teamId === item?.teamId))
      );
    }
  };

  const emailAlertsWhitoutThisTopic = () => {
    if (currentKeywordCategory.id === '') {
      return [];
    } else {
      return emailAlerts.filter(
        (item) => !item.digestCombiningAllTopics && !item.keywordListIds.includes(currentKeywordCategory.id)
      );
    }
  };

  const showCreateEmailAlert = () => {
    let emailAlertAllDigest = emailAlerts.findIndex((item) => item.digestCombiningAllTopics);
    if (emailAlertAllDigest < 0) {
      setIsOpenEditEmailAlert(true);
    } else {
      navigate('/monitor-inbox/key-updates');
    }
  };

  const braquetsNumber = () => {
    let emailAlertsLength = createEmailAlertsForThisTopic().length;
    if (emailAlertsLength.length > 0) {
      return `(${emailAlertsLength})`;
    }
  };

  const ManageEmailAlerts = () => {
    return (
      <>
        {currentKeywordCategory.id !== '' && (
          <Link to='/settings/alerts' className='simple-link-button mt-3 mt-lg-0 d-inline-block'>
            Manage alerts
          </Link>
        )}
      </>
    );
  };

  const CreateEmailAlertButton = () => {
    return (
      <div className='pb-4'>
        <button
          className={`general-button simple-link-button ${allowAlerts ? '' : ''}`}
          onClick={async () => {
            if (allowAlerts) {
              if (currentKeywordCategory.id === '') {
                document.getElementById('save-topic-button').click();
              } else {
                setIsOpenEditEmailAlert(true);
              }
            } else {
              if (myself?.role !== 'Member') {
                if (isPaidFor) {
                  let token = await getAccessToken();
                  callBillingPortal(token, location, source);
                } else {
                  navigate(pricingUrl);
                }
              }
            }
          }}
          data-tooltip-id={'settings-tooltip'}
          data-tooltip-content={allowAlerts ? '' : 'Email alerts are available on Pro and Mogul'}
          data-tooltip-place={'top'}
        >
          + Create an email alert{' '}
          {!allowAlerts && (
            <img
              className='padlock-image'
              src={`${process.env.REACT_APP_CDNURL}/images/padlock-lightblue.svg`}
              alt='padlock'
            />
          )}
        </button>
      </div>
    );
  };

  const changeDescription = (description) => {
    let keywordsListsToRender = JSON.parse(JSON.stringify(keywordsLists));
    let newList = { ...keywordsListsToRender[listPosition], description };
    keywordsListsToRender[listPosition] = newList;
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'keywordsLists',
      value: keywordsListsToRender,
    });
  };

  const setCountries = (newCountries) => {
    let keywordsListsToRender = JSON.parse(JSON.stringify(keywordsLists));
    let newList = {
      ...keywordsListsToRender[listPosition],
      countries: newCountries,
    };
    keywordsListsToRender[listPosition] = newList;
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'keywordsLists',
      value: keywordsListsToRender,
    });
  };

  const disabledCondition = (!isMyList && myself?.role === 'Member') || IsSavingKeywordList;

  return (
    <>
      {isReady && (
        <div ref={topicsComponentContainer}>
          {isMobile && (
            <div className='row mx-0 align-items-center justify-content-between py-lg-0 pl-3'>
              <div>
                <button
                  className='general-button link-button'
                  onClick={() => {
                    let initialTopic = InitialKeywordsState[listPosition];
                    let currentTopic = keywordsLists[listPosition];
                    let haveChanges;
                    if (id === '') {
                      haveChanges = name.length > 0 || keywords.length > 0 ? true : false;
                    } else {
                      haveChanges = JSON.stringify(initialTopic) === JSON.stringify(currentTopic) ? false : true;
                    }
                    if (haveChanges) {
                      setTargetLink(
                        `/settings/${transformTopicOrClientWord({
                          plural: true,
                        })}`
                      );
                      MicroModal.show('topic-discard-popup', {
                        awaitCloseAnimation: true,
                        disableFocus: true,
                      });
                    } else {
                      navigate(
                        `/settings/${transformTopicOrClientWord({
                          plural: true,
                        })}`
                      );
                    }
                  }}
                >
                  Back to {transformTopicOrClientWord({ plural: true })}
                </button>
              </div>
              <div className='text-right topic-action-buttons'>
                <TopicDeleteButton listPosition={listPosition} />
                <TopicSaveButton listPosition={listPosition} showCreateEmailAlert={showCreateEmailAlert} />
              </div>
            </div>
          )}

          <div className='row mx-0 sticky-item-lg padding-topic-component border-bottom topic-component-title align-items-center'>
            <div className='flex-grow-1'>
              <TopicName listPosition={listPosition} />
            </div>
            {!isMobile && (
              <div className='pl-3 text-right mt-3 mt-lg-0 topic-action-buttons'>
                <TopicDeleteButton listPosition={listPosition} />
                <TopicSaveButton listPosition={listPosition} showCreateEmailAlert={showCreateEmailAlert} />
              </div>
            )}
          </div>

          <div className='mb-4'>
            <input
              className='topic-component-description'
              value={currentKeywordCategory.description === null ? '' : currentKeywordCategory.description}
              placeholder={`${
                agencyUser ? "Describe this client's interests in a few words" : ' Describe what this topic is about'
              } (optional)`}
              onChange={(e) => {
                changeDescription(e.target.value);
              }}
              disabled={disabledCondition}
            />
          </div>

          <div ref={containerRef}>
            <CustomScrollbar
              className={'topics-scrollbar'}
              style={{ height: `${heightContainer}px` }}
              maximalThumbYSize={100}
            >
              <div className='topic-items-container padding-topic-component pt-3 pb-5'>
                <div className={`mb-3 d-flex justify-content-between align-items-center`}>
                  <div>
                    <h3 className='topic-title mb-0'>Keywords and phrases</h3>
                    <p className='mb-0 main-light-text'>{`Enter keywords or phrases, or click suggestions to add them to the ${topicOrClientWord}`}</p>
                  </div>
                  <button
                    className='general-button paragraph-p1-bold main-light-text align-self-start'
                    onClick={() => {
                      setShowSuggestion(!showSuggestion);
                    }}
                  >
                    {showSuggestion ? 'Hide' : 'Show'} suggestions
                  </button>
                </div>

                <KeywordsAndAdvancedSearchComponent
                  openStates={openStates}
                  setOpenStates={setOpenStates}
                  showSuggestion={showSuggestion}
                  setShowSuggestion={setShowSuggestion}
                />

                <CountriesPopupContainer
                  countries={countries}
                  setShowPopup={setShowRegionsPopup}
                  setCountries={setCountries}
                  disabled={disabledCondition}
                />
              </div>
              <div className='padding-topic-component'>
                {currentKeywordCategory.id !== '' && (
                  <div className='d-lg-flex justify-content-between mb-4 align-items-center'>
                    <h3 className='topic-title topic-title-email-alerts mb-0'>
                      Alerts featuring this {topicOrClientWord} {braquetsNumber()}
                    </h3>
                    {!isMobile && <ManageEmailAlerts />}
                  </div>
                )}
                {currentKeywordCategory.id !== '' && createEmailAlertsForThisTopic().length > 0 && (
                  <>
                    <div className='topic-items-container pb-3'>
                      <TopicEmailAlerts
                        setEmailAlertId={setEmailAlertId}
                        emailAlerts={createEmailAlertsForThisTopic()}
                        setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
                        currentKeywordCategory={currentKeywordCategory}
                      />
                      {isMobile && <ManageEmailAlerts />}
                    </div>
                  </>
                )}
                {currentKeywordCategory.id !== '' && createEmailAlertsForThisTopic().length === 0 && (
                  <CreateEmailAlertButton />
                )}
              </div>
              {!newAgencyPlan && (
                <div className='hide-topic-btn-container padding-topic-component pb-4'>
                  <HideTopicCheckbox keywordsLists={keywordsLists} item={currentList} key={currentList?.id} />
                </div>
              )}
            </CustomScrollbar>
          </div>

          <TopicDeletePopUp listPosition={listPosition} />
          <TopicDiscardPopUp discardFunction={discardFunction} />

          {showRegionsPopup && (
            <CountriesPopup
              countries={countries}
              setCountries={setCountries}
              setShowPopup={setShowRegionsPopup}
              identifier={'save-item-popup'}
            />
          )}
          {isOpenEditEmailAlert && (
            <CreateEmailAlert
              emailAlertId={emailAlertId}
              setEmailAlertId={setEmailAlertId}
              setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
              emailAlertsToSelect={emailAlertsWhitoutThisTopic()}
              topicData={{
                topicId: currentKeywordCategory.id,
                name: currentKeywordCategory.name,
              }}
              teamId={newAgencyPlan && !!teamIdParameter ? parseInt(teamIdParameter) : null}
              openCreatingNewTopic
              redirectToHome={newList}
              teamName={
                newAgencyPlan && !!teamIdParameter
                  ? clients?.find((item) => item?.teamId === parseInt(teamIdParameter))?.teamName
                  : null
              }
              customKeywordsLists={
                newAgencyPlan && !!teamIdParameter
                  ? keywordsLists?.filter((item) => item?.teamId === parseInt(teamIdParameter))
                  : keywordsLists
              }
            />
          )}

          <ReactTooltip
            id={'email-alert-tooltip'}
            effect={'solid'}
            type={'info'}
            className={'pricing-feature-tooltip'}
            backgroundColor={'#00122B'}
            textColor={'#fff'}
            multiline={true}
            arrowColor={'transparent'}
            place={'top'}
          />
        </div>
      )}
    </>
  );
};

export default TopicComponent;
