import { useContext, useEffect, useRef, useState } from 'react';
import useStakeholderNotesFunctions from '../useStakeholderNotesFunctions';
import { formatEmbargoedDate } from '../../../helpers/formatGeneralDate';
import useStakeholderEmailsFunctions from '../useStakeholderEmailsFunctions';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import Popup from '../../../../Common/PopUp';
import { Portal } from 'react-portal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHeightContainer } from '../../../utilities/useHeightContainer';
import useIsMobile from '../../../utilities/useIsMobile';
import useValidateContactsOversubscribed from '../../../utilities/useValidateContactsOversubscribed';
import { useCollapse } from 'react-collapsed';
import useEmailRecipientInfo from '../../../Influence/CrmEmails/CrmEmailComponent/EmailRecipients/useEmailRecipientInfo';
import AdditionalRecipientInfo from '../../../Influence/CrmEmails/CrmEmailComponent/EmailRecipients/AdditionalRecipientInfo';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';
import { store } from '../../../../Store';
import { AgencyImage, AgencyLogoAndExplanation, OrganizationImage } from './BioElements';

const ActivityLog = (props) => {
  const { StakeholderInformation, activePlan } = props;
  const [activeTab, setActiveTab] = useState('Notes');
  return (
    <div className='border-bottom'>
      <div className='flex-centered pb-4 px-4'>
        <button
          className={`general-button mr-3 activity-log-tab ${activeTab === 'Notes' ? 'active' : ''}`}
          onClick={() => setActiveTab('Notes')}
        >
          Notes
        </button>
        <button
          className={`general-button activity-log-tab  ${activeTab === 'Emails' ? 'active' : ''}`}
          onClick={() => setActiveTab('Emails')}
        >
          Emails
        </button>
      </div>
      {activeTab === 'Notes' && <Notes StakeholderInformation={StakeholderInformation} activePlan={activePlan} />}
      {activeTab === 'Emails' && <Emails StakeholderInformation={StakeholderInformation} activePlan={activePlan} />}
    </div>
  );
};

const Notes = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team, activePlan } = state;

  const { StakeholderInformation, isAddedToMyContacts, contentType, selectedVisibility, setSelectedVisibility } = props;
  const { user } = useAuth0();
  const { objectId } = StakeholderInformation;
  const { isLoading, userNotes, getNotes, addNote, updateNote, deleteNote, isNoteCreator } =
    useStakeholderNotesFunctions();
  const [disabledTextArea, setDisabledTextArea] = useState(false);
  const [noteToDeleteId, setNoteToDeleteId] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [heightContainer, containerRef] = useHeightContainer();

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();
  const { newAgencyPlan } = useTopicOrClientWord();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const isMobile = useIsMobile();

  const initialCall = useRef();
  initialCall.current = () => {
    getNotes({ objectId, requestSource: source, contentType });
  };

  useEffect(() => {
    initialCall.current();
    return () => {
      source.cancel('Api call cancelled by the user');
    };
  }, []);

  const createNoteController = async (e) => {
    let el = e.target;
    setTimeout(function () {
      el.style.cssText = `height:auto; padding:0;`;
      el.style.cssText = 'height:' + el.scrollHeight + `px;`;
    }, 5);
    if (e.keyCode === 13 && e.value !== '') {
      if (!e.shiftKey) {
        e.preventDefault();
        setDisabledTextArea(true);
        const addNoteProps = { objectId, text: el.value, contentType };
        if (newAgencyPlan) {
          addNoteProps.visibility = selectedVisibility;
        }
        await validateContactsOversubscribed(async () => await addNote(addNoteProps), 'note');
        setDisabledTextArea(false);
        el.value = '';
        el.style.cssText = 'height:24px;';
        el.focus();
      }
    }
  };

  return (
    <>
      <div className='border-bottom'>
        {isAddedToMyContacts ? (
          <>
            <div className='px-4 pb-4'>
              <div
                className={`rounded border px-3 py-2 d-flex align-items-start ${disabledTextArea ? 'notes-textarea-disabled' : ''}`}
              >
                <div className='pr-1'>
                  <img
                    style={{ width: '30px' }}
                    alt='avatar'
                    className='rounded-circle img-fluid'
                    src={`https://cdn.auth0.com/avatars/${Array.from(user?.given_name)[0].toLowerCase()}${Array.from(user?.family_name)[0].toLowerCase()}.png`}
                  />
                </div>
                <div
                  className='flex-grow-1 pl-2 flex-centered justify-content-center position-relative'
                  style={{ paddingTop: '4px', paddingRight: '25px' }}
                >
                  <textarea
                    rows={1}
                    className='input-empty w-100 notes-textarea'
                    placeholder='Start typing...'
                    autoFocus={!isMobile}
                    onKeyDown={createNoteController}
                    type='text'
                    disabled={disabledTextArea}
                  />
                  {newAgencyPlan && (
                    <AgencyLogoAndExplanation
                      activeTab={'Notes'}
                      selectedVisibility={selectedVisibility}
                      setSelectedVisibility={setSelectedVisibility}
                      user={user}
                    />
                  )}
                  <span className='notes-textarea-tooltip'>
                    <span
                      className='icon-eye-hide'
                      data-tooltip-content='Notes are only visible to you and your team'
                      data-tooltip-id='tooltip-mobile'
                      data-tooltip-place='top'
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className='border-top px-4 pt-3'>
              <div ref={containerRef} className='pb-3' style={{ minHeight: `${heightContainer - 1}px` }}>
                {isLoading ? (
                  <LoadingState />
                ) : (
                  <>
                    {userNotes?.length > 0 ? (
                      <>
                        {userNotes.map((item) => (
                          <UserNote
                            key={`item-${item?.id}`}
                            item={item}
                            setNoteToDeleteId={setNoteToDeleteId}
                            setShowDeletePopup={setShowDeletePopup}
                            updateNote={updateNote}
                            isNoteCreator={isNoteCreator}
                            team={team}
                            activePlan={activePlan}
                            user={user}
                            newAgencyPlan={newAgencyPlan}
                          />
                        ))}
                      </>
                    ) : (
                      <div
                        className='flex-centered justify-content-center text-center'
                        style={{ height: `${heightContainer - 36}px` }}
                      >
                        <p className='main-light-text title-h5-m' style={{ maxWidth: '420px' }}>
                          {newAgencyPlan
                            ? `${selectedVisibility === 'user' ? 'Notes' : 'General notes'} your team adds in relation to this stakeholder ${selectedVisibility === 'user' ? `for ${team?.teamName} ` : ''}will appear here`
                            : `You haven’t add any notes to this stakeholder`}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div
            ref={containerRef}
            style={{ height: `${heightContainer - 1}px` }}
            className='flex-centered justify-content-center text-center pb-5'
          >
            <p className='main-light-text title-h5-m' style={{ maxWidth: '250px' }}>
              Add this stakeholder to your contact list to create a note
            </p>
          </div>
        )}
      </div>
      {showDeletePopup && !!noteToDeleteId && (
        <DeleteNotePopup
          noteId={noteToDeleteId}
          identifier={'delete-note-popup'}
          setShowPopup={setShowDeletePopup}
          maxWidth={480}
          deleteNote={deleteNote}
        />
      )}
    </>
  );
};

const UserNote = (props) => {
  const {
    item,
    setNoteToDeleteId,
    setShowDeletePopup,
    updateNote,
    isNoteCreator,
    team,
    user,
    activePlan,
    newAgencyPlan,
  } = props;
  const { authorName, text, created, lastModified, isOrganisationWide } = item;
  const [localText, setLocalText] = useState(text);

  const [isDisabled, setIsDisabled] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const noteContent = useRef(null);

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  const handleSaveNote = async (e) => {
    let el;
    if (e) {
      el = e.target;
    } else {
      el = noteContent.current;
    }
    setTimeout(function () {
      el.style.cssText = `height:auto; padding:0;`;
      el.style.cssText = 'height:' + el.scrollHeight + `px;`;
    }, 0);

    const saveNote = async () => {
      if (el.value.trim() !== localText.trim()) {
        setIsDisabled(true);
        await updateNote({ note: item, text: el.value });
        setLocalText(el.value);
        setIsDisabled(false);
      }
      el.blur();
      setEditNote(false);
    };

    if (e) {
      if (e.keyCode === 13 && e.value !== '') {
        e.preventDefault();
        saveNote();
      }
    } else {
      saveNote();
    }
  };

  return (
    <div className='pt-3 position-relative user-note-main-container'>
      <div className='d-flex justify-content-between align-items-start'>
        <p className='mb-1 d-flex align-items-center flex-wrap note-name-item'>
          <span className='font-weight-bold mr-2 mr-lg-3'>{authorName}</span>
          <span className='main-light-text paragraph-p3 d-block'>{formatEmbargoedDate(created)}</span>
        </p>
        <div className='row mx-0'>
          {isNoteCreator({ note: item }) && (
            <div className='user-note-action-buttons d-flex justify-content-end'>
              <button
                id='save-edit-note-btn'
                data-tooltip-content={editNote ? undefined : 'Edit note'}
                data-
                data-tooltip-id={editNote ? undefined : 'general-tooltip'}
                className='general-button d-inline-block mr-3'
                onClick={() => {
                  validateContactsOversubscribed(async (e) => {
                    if (editNote) {
                      await handleSaveNote();
                    } else {
                      setEditNote(true);
                      setTimeout(() => {
                        let el = noteContent.current;
                        let textareaLength = el.value.length;
                        el.style.cssText = `height: ${el.scrollHeight}px`;
                        el.setSelectionRange(textareaLength, textareaLength);
                        el.focus();
                      }, 0);
                    }
                  }, 'note');
                }}
              >
                {editNote ? (
                  <span className='blue-text'>Save</span>
                ) : (
                  <span className='icon-edit-bold main-subtle-text' />
                )}
              </button>
              <button
                data-tooltip-content={'Delete note'}
                data-tooltip-id={'general-tooltip'}
                className='general-button'
                onClick={(e) => {
                  validateContactsOversubscribed((e) => {
                    setNoteToDeleteId(item?.id);
                    setShowDeletePopup(true);
                  }, 'note');
                }}
              >
                <span className='icon-delete paragraph-p2 main-subtle-text' />
              </button>
            </div>
          )}
          {newAgencyPlan && (
            <NoteVisibilityLogo
              isOrganisationWide={isOrganisationWide}
              team={team}
              activePlan={activePlan}
              user={user}
            />
          )}
        </div>
      </div>
      <div className={`user-note-container main-subtle-text rounded  ${isDisabled ? 'light-50-text' : ''}`}>
        {editNote ? (
          <textarea
            ref={noteContent}
            rows={1}
            defaultValue={text}
            className='input-empty w-100 notes-textarea user-notes-textarea'
            autoFocus={true}
            disabled={isDisabled}
            onKeyDown={handleSaveNote}
            onBlur={(e) => (e.relatedTarget?.id === 'save-edit-note-btn' ? undefined : setEditNote(false))}
            type='text'
          />
        ) : (
          <div className='d-flex align-items-start'>
            <p className='mb-0 note-container flex-grow-1'>
              {text}
              {!dayjs(created).isSame(dayjs(lastModified)) && (
                <span className='light-50-text d-inline-block ml-1 paragraph-p3'>(edited)</span>
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const NoteVisibilityLogo = ({ isOrganisationWide, team, activePlan, user }) => {
  return (
    <div className='ml-3' style={{ marginRight: '13px' }}>
      {!isOrganisationWide ? <AgencyImage team={team} /> : <OrganizationImage activePlan={activePlan} user={user} />}
    </div>
  );
};

const Emails = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;

  const {
    StakeholderInformation,
    sendEmailToContact,
    isAddedToMyContacts,
    contentType,
    crmContact,
    setStakeholderEmails,
  } = props;
  const [disabledButton, setDisabledButton] = useState(false);
  const { id } = StakeholderInformation;
  const { isLoading, emails, getEmails } = useStakeholderEmailsFunctions();
  const initialCall = useRef();

  initialCall.current = (source) => {
    getEmails({ id, requestSource: source, contentType });
  };

  const { newAgencyPlan } = useTopicOrClientWord();
  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Api call cancelled by the user');
    };
  }, []);

  useEffect(() => {
    setStakeholderEmails(emails);
  }, [emails]);

  const emailsWithoutDrafts = emails?.filter((item) => item.status === 'Sent');
  const [heightContainer, containerRef] = useHeightContainer();

  return (
    <div className='border-bottom'>
      {isAddedToMyContacts ? (
        <>
          <div className='px-4 pb-4'>
            <button
              className='text-left pointer action-state-hover general-button w-100 py-2 px-3 text-light-blue border rounded flex-centered general-disabled-button flex-centered'
              onClick={(e) => {
                validateContactsOversubscribed(() => {
                  setDisabledButton(true);
                  sendEmailToContact({ e });
                }, 'send');
              }}
              disabled={disabledButton}
              style={{ minHeight: '48px' }}
            >
              <span
                className='icon-email d-none d-lg-inline-block mr-3 ml-lg-1 mr-lg-2 paragraph-p3 spacing-icon-email'
                style={{ marginTop: '1px' }}
              />
              <span>
                <span className='mb-0 ml-lg-2'>
                  Add this {crmContact ? 'contact' : 'stakeholder'} to an email campaign
                </span>
              </span>
            </button>
          </div>
          <div className='border-top pt-2 pb-4' ref={containerRef} style={{ minHeight: `${heightContainer - 1}px` }}>
            {isLoading ? (
              <LoadingStateEmails />
            ) : (
              <>
                {emailsWithoutDrafts?.length > 0 ? (
                  <>
                    {emailsWithoutDrafts.map((item) => {
                      const { crmEmailId } = item;
                      return (
                        <EmailItem
                          StakeholderInformation={StakeholderInformation}
                          key={`item-${crmEmailId}`}
                          item={item}
                        />
                      );
                    })}
                  </>
                ) : (
                  <div
                    className='flex-centered justify-content-center text-center'
                    style={{ height: `${heightContainer - 36}px` }}
                  >
                    <p className='main-light-text title-h5-m' style={{ maxWidth: '420px' }}>
                      {newAgencyPlan
                        ? `Emails your team sends to this stakeholder on behalf of ${team?.teamName} will appear here`
                        : `You haven’t sent any emails to this stakeholder`}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <div
          ref={containerRef}
          style={{ height: `${heightContainer - 1}px` }}
          className='flex-centered justify-content-center text-center pb-5'
        >
          <p className='main-light-text title-h5-m px-5' style={{ maxWidth: '400px' }}>
            Add this stakeholder to your contact list to add them to an email campaign{' '}
          </p>
        </div>
      )}
    </div>
  );
};

const EmailItem = (props) => {
  const { item } = props;
  const { senderDisplayName, subject, crmEmailId, finishedSendingAt, sendRecords } = item;
  const firstName = senderDisplayName.split(' ')[0];
  const lastName = senderDisplayName.split(' ')[1];
  const [isExpanded, setIsExpanded] = useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({ isExpanded });

  const { isLoading, getRecipientInfo, recipientInfo } = useEmailRecipientInfo();

  return (
    <div
      className={`position-relative pointer stakeholder-email-item`}
      {...getToggleProps({
        onClick: () => {
          if (!isExpanded) {
            getRecipientInfo({
              emailId: crmEmailId,
              contactId: sendRecords?.[0]?.crmContactId,
            });
          }
          setIsExpanded((prev) => !prev);
        },
      })}
    >
      <div className='stakeholder-item-title px-4 pt-3 pb-2'>
        <span className={`dropdown-item-element ${isExpanded ? `open` : ''}`} />
        <div className='d-flex align-items-start mb-2'>
          <img
            style={{ width: '30px', marginTop: '-1px' }}
            alt='rounded'
            className='rounded-circle img-fluid d-inline-blcok'
            src={`https://cdn.auth0.com/avatars/${Array.from(firstName)[0].toLowerCase()}${Array.from(lastName)[0].toLowerCase()}.png`}
          />
          <div className='pl-2 pr-3'>
            <p className={`mb-0 stakeholder-email-subject font-weight-bold`}>{subject}</p>
            <p className='mb-0' style={{ lineHeight: 1.35 }}>
              <span className='stakeholder-email-description main-light-text d-inline-block'>
                {formatEmbargoedDate(finishedSendingAt)}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className='email-recipients-body pt-0' {...getCollapseProps()}>
        <div className='email-recipients-expanded-content'>
          <AdditionalRecipientInfo isLoading={isLoading} info={recipientInfo} />
        </div>
      </div>
    </div>
  );
};

const ContentDeleteNotePopup = (props) => {
  const { closeMicroModal, noteId, deleteNote } = props;

  return (
    <div className='p-4 px-lg-5 pt-5 pb-lg-2 '>
      <div className='text-center'>
        <p>Are you sure you want to remove this note?</p>

        <div className='my-4'>
          <button
            className='general-button outline-button general-button-spacing mr-2 mr-lg-3'
            onClick={() => closeMicroModal()}
          >
            Cancel
          </button>
          <button
            className='general-button general-button-spacing delete-note-button text-white'
            onClick={async (e) => {
              let el = e.target;
              el.disabled = true;
              await deleteNote({ id: noteId });
              closeMicroModal();
            }}
          >
            {`Delete note`}
          </button>
        </div>
      </div>
    </div>
  );
};

const DeleteNotePopup = (props) => {
  return (
    <Portal>
      <Popup {...props}>
        <ContentDeleteNotePopup {...props} />
      </Popup>
    </Portal>
  );
};

const LoadingState = () => {
  return (
    <>
      {Array(2)
        .fill()
        .map((item, index) => (
          <SkeletonTheme baseColor='#fff' key={index}>
            <div className='py-3 position-relative'>
              <div className='flex-centered margin-small'>
                <div className='pr-2'>
                  <Skeleton height={30} width={30} circle={true} />
                </div>
                <Skeleton height={19} width={`200px`} />
              </div>
              <div className='border py-2 px-3 rounded'>
                <Skeleton height={19} width={`100%`} />
              </div>
            </div>
          </SkeletonTheme>
        ))}
    </>
  );
};

const LoadingStateEmails = () => {
  return (
    <>
      {Array(2)
        .fill()
        .map((item, index) => (
          <SkeletonTheme baseColor='#fff' key={index}>
            <div className={`position-relative`}>
              <div className='px-4 pt-3 pb-2'>
                <div className='d-flex align-items-start mb-2'>
                  <Skeleton circle={true} width={30} height={30} />
                  <div className='pl-2'>
                    <Skeleton height={16} width={`350px`} />
                    <div className='mt-1'>
                      <Skeleton height={16} width={`130px`} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SkeletonTheme>
        ))}
    </>
  );
};

export { Notes, Emails };
export default ActivityLog;
