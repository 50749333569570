import React from 'react';
import Skeleton from 'react-loading-skeleton';

function SentPerformanceSkeleton() {
  const rateSections = ['open', 'click', 'reply'];
  return (
    <div className='email-performance-container'>
      <div className='email-performance-horizontal-container'>
        {rateSections.map((section, index) => (
          <div
            className='email-performance-section-container position-relative'
            style={{ paddingBottom: '40px' }}
            key={`${section}-${index}`}
          >
            <div className='email-performance-rate-header flex-centered'>
              <Skeleton height={20} width={160} />
            </div>
            {section === 'reply' ? (
              ['', '', ''].map((item, index) => {
                return (
                  <div
                    key={`delivery-item-${index}`}
                    className={`email-performance-rate ${index === 0 ? 'pt-3' : 'email-performance-delivery'} ${
                      index === ['', '', ''].length - 1
                        ? 'email-performance-rate-total'
                        : 'email-performance-rate-unique'
                    }`}
                  >
                    <span>
                      <Skeleton height={20} width={80} />
                    </span>
                    <span className='email-performance-link'>
                      <Skeleton height={20} width={20} />
                    </span>
                  </div>
                );
              })
            ) : (
              <>
                <Skeleton height={40} style={{ margin: '15px 0' }} />
                <div className='email-performance-rate email-performance-rate-unique'>
                  <Skeleton height={18} width={100} />
                  <span className='email-performance-link'>
                    <Skeleton height={18} width={20} />
                  </span>
                </div>
                <div className='email-performance-rate email-performance-rate-total'>
                  <Skeleton height={18} width={100} />
                  <span className='email-performance-total-text'>
                    <Skeleton height={18} width={20} />
                  </span>
                </div>
              </>
            )}
            <div className='email-performance-device'>
              <div className='d-flex'>
                <Skeleton height={18} width={65} />
                <span className='email-performance-device-percentage'>
                  <Skeleton height={18} width={25} />
                </span>
              </div>
              <div className='d-flex'>
                <Skeleton height={18} width={65} />
                <span className='email-performance-device-percentage'>
                  <Skeleton height={18} width={25} />
                </span>
              </div>
            </div>
          </div>
        ))}

        <div className='email-performance-horizontal-container w-100'>
          <div className='email-performance-section-container-top'>
            <div className='email-performance-rate-header'>
              <Skeleton height={20} width={160} />
            </div>
            <div className='top-clicked-links-table'>
              <div className='top-clicked-links-row top-clicked-links-table-header'>
                <span>
                  <Skeleton height={20} width={40} />
                </span>
                <span>
                  <Skeleton height={20} width={70} />
                </span>
              </div>
            </div>
          </div>
          <div className='email-performance-section-container-top'>
            <div className='email-performance-rate-header'>
              <Skeleton height={20} width={160} />
            </div>
            <div className='top-clicked-links-table'>
              <div className='top-clicked-links-row top-clicked-links-table-header'>
                <span>
                  <Skeleton height={20} width={40} />
                </span>
                <span>
                  <Skeleton height={20} width={70} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SentPerformanceSkeleton;
