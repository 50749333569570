import React, { useContext, useState } from 'react';
import { lowerCaseFirstLetter } from '../../utilities/uppercaseFirstLetter';
import transformToPascalCase from '../../../Settings/Utilities/TransformToPascalCase';
import normalize from '../../utilities/normalizeString';
import { RelatedContent } from './SearchRelatedContent';
import AttachmentLink from '../../components/AttachmentLink';
import { useAuth0 } from '@auth0/auth0-react';
import { useHeightContainer } from '../../utilities/useHeightContainer';
import Popup from '../../../Common/PopUp';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import createNotification from '../../../Settings/Utilities/CreateNotification';
import { SaveButton } from '../../components/Share';
import createMarkup from '../../helpers/createMarkup';
import PoliticalUpdates from './PoliticalUpdates';
import CreateAccountPopup from '../../components/CreateAccountPopup';
import { Portal } from 'react-portal';
import IndividualContentStatistics from './IndividualContentStatistics';
import { useRef } from 'react';
import KeyInformation from './KeyInformation';
import useIsMobile from '../../utilities/useIsMobile';
import { ConfirmDeletePopup, SupportPopup } from './SupportPopups';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import ThumbsDown from '../../../Common/ThumbsDown';
import FeedbackOnItem from '../../FeedbackItem/FeedbackOnItem';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { Link } from 'react-router-dom-v5-compat';
import parliamentarianUsers from '../../sidebar/parliamentarianUsers';
import { store } from '../../../Store';
import TextSelector from '../../../Common/TextSelector';
//import CreateReportFromUpdate from '../../../Common/CreateReportFromUpdate';

const useCreateDescriptionSections = (props) => {
  const { page } = props ?? {};
  const { newAgencyPlan } = useTopicOrClientWord();
  const createDescriptionSections = ({ version = 2 }) => {
    if (version === 1) {
      if (page === 'policy-ask' || page === 'policy-asks') {
        return [
          {
            property: 'aims',
            title: 'What are the aims of your policy ask?',
            subtext: 'Policy asks should be achievable. World peace would be nice, but...',
          },
          {
            property: 'whoWillBeImpacted',
            title: 'Who would benefit from this proposed change of policy?',
            subtext:
              'Is there a particular group who would benefit (e.g. farmers, children, young parents, carers, etc)?',
            optional: true,
          },
          {
            property: 'whatKindOfPoliticalSupport',
            title: 'What kind of political support do you want for this policy ask?',
            optional: true,
          },
        ];
      } else {
        return [
          {
            property: 'summary',
            title: 'Summary',
            subtext: `Explain the key points of your ${newAgencyPlan ? `client's ` : ''}publication. The full publication can be added below as an attachment.`,
          },
        ];
      }
    } else {
      return [
        {
          property: 'summary',
          title: 'Summary',
          subtext: 'Summarise your lobbying material',
        },
        {
          property: 'body',
          title: 'Description',
          subtext: `Explain the key points of your ${newAgencyPlan ? `client's ` : ''}publication. The full publication can be added below as an attachment.'`,
        },
      ];
    }
  };

  return { createDescriptionSections };
};

const PublishedContentPage = (props) => {
  const params = useParams();
  const { id } = params;
  const { isAuthenticated } = useAuth0();

  const {
    data,
    button,
    supportItem,
    publishFunction,
    updateSupport,
    team,
    item,
    alreadyMarkedFeedback,
    updateFeedback,
    index,
    page,
  } = props;
  const {
    title,
    relatedContent,
    publishedAt,
    attachments,
    supporters,
    notSetInnerHtml,
    objectType,
    objectId,
    keywords,
    visibility,
    contentVersion,
    summaryCreatedByTheAI,
    keywordsFromLists,
  } = data;

  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { code } = activePlan ?? {};

  const [politicalUpdatesState, setPoliticalUpdatesState] = useState({ activeSection: 'Key updates', pageNumber: 1 });
  const [thereIsMoreContent, setThereIsMoreContent] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoadingScroll, setIsLoadingScroll] = useState(false);

  const [currentTab, setCurrentTab] = useState('Overview');
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [regSource, setRegSource] = useState('');
  const [showSupportPopup, setShowSupportPopup] = useState(false);
  const [supporter, setSupporter] = useState(null);
  const [openFromSupport, setOpenFromSupport] = useState(false);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const { createDescriptionSections } = useCreateDescriptionSections({ page });
  const descriptionSections = createDescriptionSections({ version: contentVersion });
  const tabs = [
    { name: 'Overview' },
    { name: 'Supporters' },
    {
      name: 'Political updates',
      condition: keywords?.length > 0,
    },
    {
      name: 'Engagement statistics',
      condition: button === 'Edit',
    },
  ];

  const createMarkup = (body) => {
    return { __html: body };
  };

  const sortSupporters = (a, b) => {
    return (
      (b.reason?.length ? 1 : 0) - (a.reason?.length ? 1 : 0) ||
      new Date(b.dateTimeSupportAdded) - new Date(a.dateTimeSupportAdded)
    );
  };

  const supportButtons = button === 'Support this' || button === 'Register';
  const [isOpen, setIsOpen] = useState(false);
  const [heightContainer, containerRef] = useHeightContainer();
  const location = useLocation();
  const isMobile = useIsMobile();

  //INFINITE SCROLL
  const handleScroll = (scrollValues) => {
    const { scrollTop, clientHeight, scrollHeight } = scrollValues;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    const element = keyInformationElement.current;
    const keyInformationHeight = element?.getBoundingClientRect()?.height;
    if (clientHeight < keyInformationHeight && !isMobile) {
      if (scrollTop + clientHeight >= keyInformationHeight) {
        element.style.position = 'sticky';
        element.style.top = `-${keyInformationHeight - clientHeight}px`;
      } else {
        element.style.position = 'relative';
        element.style.top = null;
      }
    }
    setIsScrolled(scrollTop > 0);
    if (scrolledToBottom && currentTab === 'Political updates' && !isLoadingScroll) {
      load_more_results();
    }
  };

  const load_more_results = async () => {
    if (thereIsMoreContent) {
      setIsLoadingScroll(true);
      setPoliticalUpdatesState({ ...politicalUpdatesState, pageNumber: politicalUpdatesState.pageNumber + 1 });
    }
  };

  const saveButtonCondition = !location.pathname.includes('/create') && !location.pathname.includes('/edit');
  const scrollBarContainer = useRef();
  const keyInformationElement = useRef(null);
  const attachmentsContainer = useRef(null);
  const relatedContentContainer = useRef(null);
  const overviewContainer = useRef(null);

  const addSupport = async (e) => {
    let support = await supportItem(e);
    if (support) {
      setSupporter(support?.supporters?.find((item) => item?.id === support?.resultSupporterId));
      setOpenFromSupport(true);
      setShowSupportPopup(true);
    }
  };

  const { agencyUser } = useTopicOrClientWord();
  const scrollToTop = () => {
    scrollBarContainer?.current?.holderElement?.classList.remove('smooth-scrolling');
    scrollBarContainer.current.scrollToTop();
    scrollBarContainer.current.scrollToLeft();
  };

  return (
    <div className='bg-white'>
      <div
        className={`bg-grey z-max position-relative border-bottom ${isScrolled ? 'scrolled-title border-bottom-transparent' : ''}`}
      >
        <div className='influence-content-page main-content-wrapper px-3'>
          <div className='d-lg-flex justify-content-between py-3 py-lg-5 align-items-start'>
            {notSetInnerHtml ? (
              <h1 className='title-h3-bold influence-preview-title flex-grow'>{title}</h1>
            ) : (
              <h1
                className='title-h3-bold influence-preview-title flex-grow'
                dangerouslySetInnerHTML={createMarkup(title)}
              />
            )}

            <div className='d-flex content-header-actions flex-wrap'>
              {item && (
                <>
                  <ThumbsDown
                    contentPage={true}
                    item={item}
                    nameSection={'Influence'}
                    show={!!keywordsFromLists}
                    updateFeedback={updateFeedback}
                    alreadyMarked={alreadyMarkedFeedback}
                    keywordsFromLists={data?.keywordsFromLists}
                  />
                </>
              )}
              {saveButtonCondition && (
                <SaveButton
                  item={{
                    id,
                    title,
                    type: objectType,
                    objectID: objectId,
                    contentType: page === 'policy-asks' ? 'PolicyAsk' : transformToPascalCase(page),
                  }}
                  showText={true}
                />
              )}
              {(button === 'Edit' || button === 'Back') && (
                <Link
                  to={`/influence/${page}/edit/${id ?? data?.id}/${button === 'Back' ? '4' : '1'}?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`}
                  className={`${saveButtonCondition ? 'ml-2 ml-lg-3' : ''} border border-grey bg-main-white text-light-blue simple-link-button rounded published-page-button px-lg-4 py-lg-2 text-decoration-none action-state-hover published-page-edit-button`}
                >
                  {button}
                </Link>
              )}
              {(publishFunction || visibility !== 'StakeholdersOnly') && (
                <button
                  className={`cursor-pointer rounded published-page-button px-lg-4 published-page-button py-lg-2 ml-2 ml-lg-3 border text-decoration-none ${supportButtons ? 'simple-link-button action-state-hover bg-main-white' : 'action-button'}`}
                  onClick={(e) => {
                    if (publishFunction) {
                      let el = e.target;
                      el.disabled = true;
                      publishFunction();
                    } else {
                      setIsOpen(true);
                    }
                  }}
                >
                  {publishFunction ? (
                    `${!!id && !!publishedAt ? 'Republish' : 'Publish'}`
                  ) : (
                    <>
                      <span className='icon-share mr-2' />
                      Share
                    </>
                  )}
                </button>
              )}

              {!publishFunction && visibility === 'StakeholdersOnly' && (
                <button
                  data-tooltip-id={'general-tooltip'}
                  data-tooltip-content={
                    'This piece of content has been made available to policymakers only, and is not shareable'
                  }
                  className='rounded px-4 py-2 ml-2 ml-lg-3 border text-decoration-none main-light-text bg-main-white'
                >
                  <span className='icon-visibility policymakers-icon' />
                  Policymakers only
                </button>
              )}

              {button === 'Supported' && (
                <button
                  className='outline-button ml-2 ml-lg-3 px-4'
                  onClick={() => {
                    setShowConfirmDeletePopup(true);
                  }}
                >
                  <span className='icon-tick mr-2 paragraph-p4' />
                  {button}
                </button>
              )}

              {supportButtons && (
                <button
                  className={`action-button general-button ml-2 ml-lg-3 px-4`}
                  onClick={(e) => {
                    if (isAuthenticated) {
                      addSupport(e);
                    } else {
                      setRegSource('pm-main-support-this');
                      setShowRegisterPopup(true);
                    }
                  }}
                >
                  {button}
                </button>
              )}
            </div>
          </div>
          <div className='d-none d-lg-block'>
            {tabs.map((tab) => {
              const { name, condition } = tab;
              if (condition === undefined || condition) {
                return (
                  <button
                    onClick={() => {
                      scrollToTop();
                      setCurrentTab(name);
                      setPoliticalUpdatesState({ ...politicalUpdatesState, pageNumber: 1 });
                    }}
                    key={`${normalize(name)}`}
                    className={`border border-bottom-0 general-button px-4 mr-3 py-2 rounded-top ${name === currentTab ? 'bg-main-blue text-white border-main-blue' : 'bg-main-white action-state-hover'} `}
                  >
                    {name} {name === 'Supporters' && supporters?.length > 0 ? `(${supporters.length})` : ''}
                  </button>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
      <div ref={containerRef} />
      <CustomScrollbar
        ref={scrollBarContainer}
        className={'simple-scrollbar'}
        maximalThumbYSize={100}
        style={{ height: `${heightContainer}px` }}
        onScroll={(scrollValues) => {
          handleScroll(scrollValues);
        }}
        onScrollStart={() => {
          setScrolling(true);
        }}
        onScrollStop={() => {
          setScrolling(false);
        }}
      >
        {currentTab === 'Engagement statistics' ? (
          <IndividualContentStatistics item={data} />
        ) : (
          <div className='influence-content-page main-content-wrapper px-3'>
            <div className='d-flex flex-column-reverse flex-lg-row mx-0 justify-content-between align-items-start'>
              <div
                className={`user-content-page-main ${currentTab === 'Political updates' ? 'col-lg-12 pb-2' : 'col-lg-10 pb-5 pb-lg-4'} pr-lg-5 pl-lg-0 `}
              >
                {currentTab === 'Overview' && (
                  <div ref={overviewContainer} className='pt-4 pt-lg-5'>
                    {alreadyMarkedFeedback && (
                      <div className='pb-3'>
                        <FeedbackOnItem
                          contentPage={true}
                          item={item}
                          updateFeedback={updateFeedback}
                          feedback={data?.feedback}
                          keywordsFromLists={data?.keywordsFromLists}
                          index={index}
                        />
                      </div>
                    )}
                    <TextSelector currentNew={data} scrollbarRef={scrollBarContainer} scrolling={scrolling}>
                      {descriptionSections.map((item) => {
                        const { property, title } = item;
                        const content =
                          data[
                            `${page === 'policy-asks' ? 'policyAsk' : lowerCaseFirstLetter(transformToPascalCase(page))}Content`
                          ]?.[property];
                        const contentWithoutHTML =
                          !!content && typeof content === 'string' ? content.replace(/<\/?[^>]+(>|$)/g, '') : '';
                        return (
                          <React.Fragment key={`item-${property}`}>
                            {!!contentWithoutHTML.trim() && (
                              <>
                                <h3 className='title-h5-m-bold mb-2'>{title}</h3>
                                {title === 'Summary' && summaryCreatedByTheAI && (
                                  <div className='rounded border bg-grey p-3 mb-4'>
                                    <p className='mb-0 main-light-text'>
                                      <span className='icon-visibility d-inline-block mr-2 paragraph-p2' />
                                      Only visible to you
                                    </p>
                                    <p className='text-red-dark mb-0'>
                                      This summary has been created using AI. You can edit or remove it{' '}
                                      <Link
                                        to={`/influence/${params.page}/edit/${data?.id}/${agencyUser ? 3 : 2}`}
                                        className='text-red-dark text-underline'
                                      >
                                        here
                                      </Link>
                                      .
                                    </p>
                                  </div>
                                )}
                                <div
                                  className='mb-5 paragraph-general content-text-color published-content-description'
                                  dangerouslySetInnerHTML={createMarkup(content)}
                                />
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </TextSelector>
                    <div ref={attachmentsContainer}>
                      {attachments.length > 0 && (
                        <div className='mb-5'>
                          <h3 className='title-h5-m-bold mb-3'>Attachments</h3>
                          {attachments.map((attachment) => (
                            <AttachmentLink key={`attachment-${attachment}`} url={attachment} />
                          ))}
                        </div>
                      )}
                    </div>
                    <div ref={relatedContentContainer}>
                      {relatedContent?.length > 0 ? (
                        <>
                          <h3 className='title-h5-m-bold mb-3'>Associated content</h3>
                          <ul className='policy-list p-0'>
                            <RelatedContent relatedContent={relatedContent} />
                          </ul>
                        </>
                      ) : (
                        <>
                          {(button === 'Edit' || button === 'Back') && (
                            <>
                              <h3 className='title-h5-m-bold mb-3'>Associated content</h3>
                              <p className='content-text-color paragraph-general'>
                                {button === 'Edit' || button === 'Back'
                                  ? `You haven't associated any content with this ${page === 'policy-asks' ? 'policy ask' : page.replace('-', ' ')}`
                                  : 'No associated content yet'}
                                .
                              </p>
                              <Link
                                to={`/influence/${page}/edit/${id ?? data?.id}/${agencyUser ? '5' : '4'}?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`}
                                className='mt-2 d-inline-block px-4 py-2 action-button general-button'
                              >
                                Associate content
                              </Link>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {currentTab === 'Supporters' && (
                  <>
                    {(visibility !== 'StakeholdersOnly' ||
                      (visibility === 'StakeholdersOnly' && supporters?.length > 0)) && (
                      <h3 className='title-h5-m-bold mb-3 pt-4 pt-lg-5'>Supporters</h3>
                    )}
                    {visibility === 'StakeholdersOnly' && (
                      <p
                        className={`rounded px-3 py-2 d-none d-lg-block mb-4 stakeholders-only-banner ${supporters?.length > 0 ? '' : 'mt-4 mt-lg-5'}`}
                      >
                        {`This piece of content has been made available to policymakers only, and cannot be ${parliamentarianUsers(code) ? 'seen or supported by' : 'shared with'} other organisations`}
                      </p>
                    )}
                    {supporters?.length > 0 ? (
                      <ul className='policy-list px-0'>
                        {supporters?.sort(sortSupporters)?.map((item, index) => {
                          const { organisationName, firstName, lastName, organisationLogo, reason, email } = item;
                          const isSupporter = email === team?.myself?.emailAddress;
                          return (
                            <li
                              className={`d-flex my-3 align-items-${!!reason ? 'start supporter-list-item-padding' : 'center p-3'} box-rounded position-relative ${isSupporter ? 'item-supporter' : ''}`}
                              key={`item-supporter-${index}`}
                              onClick={() => {
                                if (isSupporter) {
                                  setSupporter(item);
                                  setShowSupportPopup(true);
                                }
                              }}
                            >
                              <img
                                src={
                                  organisationLogo ??
                                  `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`
                                }
                                alt='company logotype'
                                className='bigger-company-logotype'
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`;
                                }}
                              />
                              <div className='pl-3 flex-grow-1'>
                                <div className='flex-centered justify-content-between'>
                                  <p className='supporter-title paragraph-p2 main-subtle-text preview-organisation-item mb-0 adjust-line-height'>
                                    <span>
                                      {firstName} {lastName}, {organisationName}
                                    </span>
                                  </p>
                                  {!isMobile && isSupporter && (
                                    <p className='mb-0 text-light-blue pr-2 action-text'>
                                      {reason ? 'Edit' : 'Add'} support reason
                                    </p>
                                  )}
                                </div>
                                {reason && (
                                  <p className={`mb-0 light-50-text paragraph-p2 mt-${isSupporter ? 2 : 1}`}>
                                    "{reason}"
                                  </p>
                                )}
                              </div>
                              {isSupporter && isMobile && (
                                <span className='position-absolute icon-edit-pencil text-light-blue' />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <>
                        {!!publishedAt && button === 'Support this' ? (
                          <>
                            <p className='mb-4 content-text-color paragraph-general'>
                              Be the first to support this lobbying material.
                            </p>
                            <button
                              className='action-button general-button px-4 '
                              onClick={(e) => {
                                if (isAuthenticated) {
                                  addSupport(e);
                                } else {
                                  setRegSource('pm-supporters-support-this');
                                  setShowRegisterPopup(true);
                                }
                              }}
                            >
                              Support this
                            </button>
                          </>
                        ) : (
                          visibility !== 'StakeholdersOnly' && (
                            <p className='content-text-color paragraph-general' style={{ maxWidth: '28rem' }}>
                              {button === 'Edit'
                                ? 'No supporters yet. Share this page with other organisations to gather support.'
                                : `Once you publish this lobbying material, you'll be able to gather support from other organisations.`}
                            </p>
                          )
                        )}
                      </>
                    )}
                  </>
                )}
                {currentTab === 'Political updates' && (
                  <PoliticalUpdates
                    keywords={keywords}
                    scrollToTop={scrollToTop}
                    politicalUpdatesState={politicalUpdatesState}
                    setPoliticalUpdatesState={setPoliticalUpdatesState}
                    setThereIsMoreContent={setThereIsMoreContent}
                    button={button}
                    id={id ?? data?.id}
                    objectID={objectId}
                    isLoadingScroll={isLoadingScroll}
                    setIsLoadingScroll={setIsLoadingScroll}
                    thereIsMoreContent={thereIsMoreContent}
                    page={page}
                  />
                )}
              </div>
              <KeyInformation
                ref={keyInformationElement}
                data={data}
                currentTab={currentTab}
                refs={{ relatedContentContainer, attachmentsContainer, overviewContainer, scrollBarContainer }}
              />
            </div>
          </div>
        )}
      </CustomScrollbar>
      {isOpen && (
        <SharePopup
          identifier={'share-popup'}
          setShowPopup={setIsOpen}
          data={data}
          additionalClass={'share-container-popup'}
        />
      )}
      {showRegisterPopup && (
        <CreateAccountPopup
          regSource={regSource}
          setShowPopup={setShowRegisterPopup}
          text={'You’ll need to create an account to add your support'}
          listItems={[
            'Create and support lobbying material',
            'Monitor the political landscape',
            'Identify relevant political stakeholders',
          ]}
        />
      )}
      {showSupportPopup && (
        <SupportPopup
          setShowPopup={setShowSupportPopup}
          identifier={'support-popup'}
          data={data}
          additionalClass={'content-support-popup'}
          disableFocus={false}
          supportItem={supportItem}
          supporter={supporter}
          openFromSupport={openFromSupport}
          additionalCloseAction={() => setOpenFromSupport(false)}
          updateSupport={updateSupport}
        />
      )}
      {showConfirmDeletePopup && (
        <ConfirmDeletePopup
          setShowPopup={setShowConfirmDeletePopup}
          identifier={'confirm-delete-popup'}
          supportItem={supportItem}
          additionalClass={'delete-keywordcategory-modalp'}
          page={page}
        />
      )}
    </div>
  );
};

const ContentPopup = (props) => {
  const { data } = props;
  let { rawTitle, title, canonicalUrl, visibility } = data;
  title = rawTitle ? rawTitle : title;
  return (
    <>
      <div className='pt-4 section-title px-lg-5 px-4 pb-3 border-bottom'>
        <h3 className={`topic-title mb-0 font-weight-bold`} dangerouslySetInnerHTML={createMarkup(title)}></h3>
      </div>
      <div className='px-lg-5 px-4'>
        <ShareButtons
          canonicalUrl={canonicalUrl}
          title={title}
          disabledText={
            visibility === 'StakeholdersOnly'
              ? 'This piece of content has been made available to policymakers only, and cannot be shared publicly'
              : undefined
          }
        />
      </div>
      <div className='border-top py-3 rounded-bottom text-right px-lg-5 px-4 bg-salmon'>
        {visibility !== 'StakeholdersOnly' && (
          <p className='mb-0 paragraph-p3'>
            <span className='d-inline-block mr-1'>Powered by</span>{' '}
            <img
              style={{ width: '118px' }}
              alt='policyinfluence-logo'
              src={`${process.env.REACT_APP_CDNURL}/images/influence/20221104-policyinfluence-logo.svg`}
            />
          </p>
        )}
      </div>
    </>
  );
};

const ShareButtons = (props) => {
  const { canonicalUrl, onClick = () => {}, title, disabledText } = props;
  return (
    <div
      className='d-flex flex-column mx-auto position-relative py-5 share-buttons-modal'
      style={{ maxWidth: '250px' }}
    >
      <button
        className={`cursor-pointer bg-main-white text-purple-100 rounded border copy-link-button py-2 text-light-blue mb-3`}
        onClick={() => {
          if (onClick) {
            onClick();
          }
          let tempInput = document.createElement('input');
          tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
          tempInput.value = `${process.env.REACT_APP_POLICYINFLUENCE_APP_URL}${canonicalUrl}?utm_source=share-button`;
          document.body.appendChild(tempInput);
          tempInput.select();
          document.execCommand('copy');
          document.body.removeChild(tempInput);
          createNotification('success', 'Link copied');
        }}
        data-tooltip-id={'general-tooltip'}
        data-tooltip-class-name={'share-button-tooltip'}
        disabled={!!disabledText}
        data-tooltip-content={
          disabledText
            ? 'This piece of content has been made available to policymakers only, and is not shareable'
            : undefined
        }
      >
        <span className={`icon-copy mr-2 inline-block`} /> Copy link
      </button>
      <TwitterShareButton
        url={`${process.env.REACT_APP_POLICYINFLUENCE_APP_URL}${canonicalUrl}?utm_source=share-button`}
        title={title}
        className={`action-button general-button px-4 py-2`}
        resetButtonStyle={false}
        onClick={onClick}
        data-tooltip-id={'general-tooltip'}
        data-tooltip-class-name={'share-button-tooltip'}
        disabled={!!disabledText}
        disabledStyle={{
          background: 'rgba(0, 18, 43, 0.05)',
          color: 'rgba(0, 28, 53, 0.7)',
          cursor: 'default',
        }}
        data-tooltip-content={
          disabledText
            ? 'This piece of content has been made available to policymakers only, and is not shareable'
            : undefined
        }
      >
        <span className={`icon-twitter-x d-inline-block`} />
        <span className='pl-2'>Share on X</span>
      </TwitterShareButton>

      <LinkedinShareButton
        title={title}
        source={'Policy influence'}
        className={`action-button general-button px-4 py-2 my-3`}
        url={`${process.env.REACT_APP_POLICYINFLUENCE_APP_URL}${canonicalUrl}?utm_source=share-button`}
        resetButtonStyle={false}
        onClick={onClick}
        data-tooltip-id={'general-tooltip'}
        data-tooltip-class-name={'share-button-tooltip'}
        disabled={!!disabledText}
        disabledStyle={{
          background: 'rgba(0, 18, 43, 0.05)',
          color: 'rgba(0, 28, 53, 0.7)',
          cursor: 'default',
        }}
        data-tooltip-content={
          disabledText
            ? 'This piece of content has been made available to policymakers only, and is not shareable'
            : undefined
        }
      >
        <span className={`icon-linkedin d-inline-block`} /> <span className='pl-2'>Share on LinkedIn</span>
      </LinkedinShareButton>

      <FacebookShareButton
        quote={title}
        url={`${process.env.REACT_APP_POLICYINFLUENCE_APP_URL}${canonicalUrl}?utm_source=share-button`}
        resetButtonStyle={false}
        onClick={onClick}
        className={`action-button general-button px-4 py-2`}
        data-tooltip-id={'general-tooltip'}
        data-tooltip-class-name={'share-button-tooltip'}
        disabled={!!disabledText}
        disabledStyle={{
          background: 'rgba(0, 18, 43, 0.05)',
          color: 'rgba(0, 28, 53, 0.7)',
          cursor: 'default',
        }}
        data-tooltip-content={
          disabledText
            ? 'This piece of content has been made available to policymakers only, and is not shareable'
            : undefined
        }
      >
        <span
          className={`icon-facebook d-inline-block title-h3`}
          style={{ marginTop: '-3px', verticalAlign: 'middle' }}
        />{' '}
        <span className='pl-2'>Share on Facebook</span>
      </FacebookShareButton>
    </div>
  );
};

const SharePopup = (props) => {
  return (
    <Portal>
      <Popup {...props}>
        <ContentPopup {...props} />
      </Popup>
    </Portal>
  );
};

export { SharePopup, ShareButtons, useCreateDescriptionSections };
export default PublishedContentPage;
