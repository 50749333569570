import { useEffect, useRef, useState } from 'react';
import DescriptionTinyEditor from '../../Dashboard/UserContent/ui/DescriptionTinyEditor';
import createMarkup from '../../Dashboard/helpers/createMarkup';
import { Portal } from 'react-portal';
import { ListIcon } from './Macros';
import { useContext } from 'react';
import { store } from '../../Store';

const removeElement = (container) => {
  let element = container.current.querySelector('.report-commentary-saved');
  if (element) {
    element.remove();
  }
};

const CommentaryComponent = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { autofocusCommentary } = state;

  const { item, modifyRowSpan, previewMode, macrosState, modifyItemLayout, layout } = props;
  const { macrosList } = macrosState ?? {};
  const { value, saved, type } = item?.data?.state ?? {};
  const [localValue, setLocalValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState('676a75');

  const editorRef = useRef(null);
  const editorToEdit = useRef();
  const container = useRef();
  const searchTimeout = useRef(null);
  const typing = useRef(null);
  const contentCommentary = useRef();

  const onChangeFunction = (content, editor) => {
    //AE: The idea here is having a local value to render it quickly and doesn't affect the item state when we're updating the rowspan. But we need a way to save the value into the main state, now on blur we're going to update that value on the main state.
    setLocalValue(content);

    if (typing.current) clearTimeout(typing.current);
    typing.current = setTimeout(() => {
      let element = editor.iframeElement?.contentWindow?.tinymce;
      if (element) {
        let bodyHeight = Math.ceil(element?.getBoundingClientRect()?.height);
        let barAndPadding = 67;
        let headerValue = 55;
        let sum = bodyHeight + barAndPadding + headerValue;
        let rowSpan = Math.ceil(sum / 96);
        modifyRowSpan({ item, rowSpan });
      }
    }, 200);
  };

  const additionalSetupFunction = (editor) => {
    if (editor) {
      return editor.ui.registry.addButton('insertMacrosButton', {
        type: 'button',
        text: 'Insert macro',
        tooltip: '',
        icon: 'document-properties',
        onAction: function () {
          setIsOpen(true);
        },
      });
    }
  };

  const addMacroIntoCommentary = (macro) => {
    const { content } = macro?.data;
    if (content && editorToEdit.current) {
      editorToEdit.current.selection?.select(editorToEdit.current.getBody(), true);
      editorToEdit.current.selection?.collapse(false);
      editorToEdit.current?.focus();
      editorToEdit.current?.execCommand('mceInsertContent', false, `<div>${content}</div>`);
    }
  };

  const createSavedElement = () => {
    let toolbar = container.current?.querySelector('.tox-toolbar__primary');
    if (toolbar) {
      let element = document.createElement('p');
      element.innerHTML = '<span class="icon-tick"></span> Saved';
      let elementClasses = ['mb-0', 'report-commentary-saved'];
      element.classList.add(...elementClasses);
      toolbar.append(element);
    }
  };

  const keyupfunction = () => {
    removeElement(container);
    if (searchTimeout.current) clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => createSavedElement(), 500);
    const { value, selectedColor } = editorRef.current.props ?? {};
    if (value === '' && selectedColor !== '676a75') {
      editorToEdit.current.execCommand('mceApplyTextcolor', 'forecolor', `#${selectedColor}`);
    }
  };

  const saveCommentaryOnBlur = (e) => {
    const body = document.getElementsByTagName('body')[0];
    let notSavedCondition =
      e.classList?.contains('commentary-macro-item') ||
      e.classList?.contains('color-selector') ||
      e.classList?.contains('general-close-background') ||
      body.classList.value.includes('awsui_show-grab-cursor');
    let commentaryValue = editorRef.current?.props?.value;
    if (!notSavedCondition && commentaryValue !== '') {
      setTimeout(() => {
        modifyItemLayout({ item, state: { ...item?.data?.state, value: commentaryValue, saved: true } });
      }, 100);
    }
  };

  const adjustValueOfItem = () => {
    if (localValue !== '') {
      let headerValue = 55;
      let rowSpan;
      if (saved) {
        let verticalPadding = 24;
        let element = contentCommentary.current;
        if (element) {
          let bodyHeight = Math.ceil(element?.getBoundingClientRect()?.height);
          let sum = verticalPadding + bodyHeight + headerValue;
          rowSpan = Math.ceil(sum / 96);
        }
        if (rowSpan !== item?.rowSpan) {
          modifyRowSpan({ item, rowSpan });
        }
      }
    }
  };

  const onInitFunction = (editor) => {
    if (localValue !== '') {
      let headerValue = 55;
      let rowSpan;
      let element = editor?.iframeElement?.contentWindow?.tinymce;
      if (element) {
        let bodyHeight = Math.ceil(element?.getBoundingClientRect()?.height);
        let barAndPadding = 67;
        let sum = bodyHeight + barAndPadding + headerValue;
        rowSpan = Math.ceil(sum / 96);
        if (rowSpan !== item?.rowSpan) {
          modifyRowSpan({ item, rowSpan });
        }
      }
    }
  };

  const itemHeight = 223 + ((item?.rowSpan < 2 ? 2 : item?.rowSpan) - 3) * 110;

  useEffect(() => {
    function handleClickOutside(event) {
      if (container.current && !container.current?.contains(event.target)) {
        saveCommentaryOnBlur(event.target);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [container, layout]);

  useEffect(() => {
    if (autofocusCommentary && item?.definition?.prebuild && !!editorToEdit.current) {
      editorToEdit.current.execCommand('mceFocus', false, editorToEdit.current.id);
      dispatch({ type: 'MODIFY_SECTION', parameter: 'autofocusCommentary', value: false });
    }
  }, [autofocusCommentary]);

  useEffect(() => {
    adjustValueOfItem();
  }, [saved, editorToEdit.current]);

  return (
    <>
      {previewMode || saved ? (
        <div
          className={`reports-preview-commentary-component ${!previewMode ? 'cursor-text' : ''}`}
          style={{ minHeight: `${itemHeight}px` }}
          onClick={() => {
            if (!previewMode) {
              modifyItemLayout({ item, state: { ...item?.data?.state, saved: false } });
            }
          }}
        >
          <div ref={contentCommentary} dangerouslySetInnerHTML={createMarkup(localValue)} />
        </div>
      ) : (
        <div
          className={`reports-commentary-container rounded position-relative influence-description ${macrosList?.length > 0 && type !== 'macro' ? 'reports-commentary-with-macros' : ''}`}
          style={{ minHeight: `${itemHeight}px` }}
          ref={container}
        >
          <DescriptionTinyEditor
            ref={editorRef}
            editorToEdit={editorToEdit}
            onChangeFunction={onChangeFunction}
            value={localValue}
            index={0}
            placeholder='Start typing'
            reportWidget={true}
            additionalSetupFunction={additionalSetupFunction}
            minHeight={itemHeight}
            parent={container.current}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            keyupfunction={keyupfunction}
            onInitFunction={onInitFunction}
          />
        </div>
      )}
      {isOpen && (
        <Portal>
          <InsertMacro
            setIsOpen={setIsOpen}
            macrosList={macrosList}
            addMacroIntoCommentary={addMacroIntoCommentary}
            parent={container.current}
          />
        </Portal>
      )}
    </>
  );
};

const InsertMacro = (props) => {
  const { parent, setIsOpen, macrosList, addMacroIntoCommentary } = props;

  const btn = parent?.querySelectorAll('.tox-tbtn');
  const insertMacroButton = !!btn ? btn[5] : null;
  const { top, left, height, width } = insertMacroButton?.getBoundingClientRect() ?? {};
  return (
    <>
      <div className='general-close-background z-max' onClick={() => setIsOpen(false)} />
      <div
        className='login-navbar py-2 overflow-auto scroll-container z-max'
        style={{ top: `${top + height}px`, left: `${left - 10}px`, width: `${width + 20}px`, maxHeight: `230px` }}
      >
        {macrosList?.map((item) => {
          const { name, id, icon } = item?.data ?? {};
          return (
            <div
              key={`macro-item-${id}`}
              onClick={(e) => {
                addMacroIntoCommentary(item);
                setIsOpen(false);
              }}
              className={`sidebar-item px-4 pointer`}
            >
              <div className='d-flex'>
                <ListIcon icon={icon} />
                <span className='hidden-lines hidden-one-line commentary-macro-item'>{name}</span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const LocalWidgetCommentary = (props) => {
  const [selectedColor, setSelectedColor] = useState('7e8c8d');
  const [initialEditorHeight, setInitialEditorHeight] = useState(0);

  const { item, previewMode, modifyItemLayout, layout } = props;
  const { commentary } = item?.data?.state ?? {};
  const editorRef = useRef(null);
  const editorToEdit = useRef();
  const containerLocal = useRef();
  const container = useRef();
  const searchTimeout = useRef();

  const additionalSetupFunction = (editor, ref) => {
    if (editor) {
      editor.ui.registry.addButton('removeCommentary', {
        type: 'button',
        text: 'Delete',
        tooltip: 'Remove commentary',
        icon: '',
        onAction: () => {
          let editorHeight = ref?.current?.elementRef?.current?.closest('div')?.getBoundingClientRect()?.height;
          let editorSpanHeight = Math.floor(editorHeight / 96);
          let layoutObj = {
            item,
            state: { ...item?.data?.state, commentary: null },
          };
          let actualSpanHeight = parseFloat(
            window
              .getComputedStyle(ref?.current?.elementRef?.current?.closest("div[class*='awsui_grid__item']"), null)
              .getPropertyValue('grid-area')
              ?.split('/')?.[2]
              ?.split(' ')?.[2]
          );
          if (editorHeight && actualSpanHeight && !isNaN(actualSpanHeight)) {
            layoutObj.rowSpan = actualSpanHeight - editorSpanHeight;
          }
          ref?.current?.props?.modifyItemLayout(layoutObj);
        },
      });
    }
  };

  const createSavedElement = () => {
    let toolbarBtns = container.current?.querySelectorAll('.tox-toolbar__group');
    if (toolbarBtns) {
      let finalElement = toolbarBtns[toolbarBtns.length - 1];
      let element = document.createElement('p');
      element.innerHTML = '<span class="icon-tick"></span> Saved';
      let elementClasses = ['mb-0', 'report-commentary-saved'];
      element.classList.add(...elementClasses);
      finalElement.before(element);
    }
  };

  const keyupfunction = (e) => {
    removeElement(container);
    if (searchTimeout.current) clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => createSavedElement(), 500);
    const { value, selectedColor } = editorRef.current.props ?? {};
    if (value === '' && selectedColor !== '676a75') {
      editorToEdit.current.execCommand('mceApplyTextcolor', 'forecolor', `#${selectedColor}`);
    }
  };

  const saveCommentaryOnBlur = (e) => {
    let notSavedCondition =
      e.classList?.contains('commentary-macro-item') ||
      e.classList?.contains('color-selector') ||
      e.classList?.contains('general-close-background');
    let commentaryValue = editorRef.current?.props?.value;
    if (!notSavedCondition && commentaryValue !== '') {
      setTimeout(() => {
        modifyItemLayout({
          item,
          state: { ...item?.data?.state, commentary: { value: commentaryValue, saved: true } },
        });
      }, 100);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (container.current && !container.current?.contains(event.target)) {
        saveCommentaryOnBlur(event.target);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [container, layout]);

  useEffect(() => {
    if (!initialEditorHeight && editorRef?.current?.elementRef?.current?.parentElement) {
      setInitialEditorHeight(editorRef?.current?.elementRef?.current?.parentElement?.getBoundingClientRect()?.height);
    }
  }, [editorRef?.current]);

  const getHeightSpanDiff = () => {
    let currentEditorHeight = editorRef?.current?.elementRef?.current?.parentElement?.getBoundingClientRect()?.height;
    if (currentEditorHeight && initialEditorHeight) {
      let heightDiff = currentEditorHeight - initialEditorHeight;
      let heightSpanDiff = heightDiff > 0 ? Math.floor(heightDiff / 96) : Math.ceil(heightDiff / 96);
      if (heightSpanDiff !== 0) {
        setInitialEditorHeight(editorRef?.current?.elementRef?.current?.parentElement?.getBoundingClientRect()?.height);
        setTimeout(() => {
          editorToEdit?.current?.selection?.select(editorToEdit?.current?.getBody(), true);
          editorToEdit?.current?.selection?.collapse(false);
        }, 5);
      }
      return heightSpanDiff;
    }
    return 0;
  };

  return (
    <div
      ref={containerLocal}
      style={
        item?.data?.component === 'MostInterestedStakeholders' || item?.data?.component === 'LatestNews'
          ? { padding: '0 20px' }
          : null
      }
    >
      {!!commentary && (
        <>
          {previewMode || commentary.saved ? (
            <div
              className={`position-relative z-extra-max local-commentary paragraph-p1 ${!previewMode ? 'cursor-text' : ''}`}
              onClick={() => {
                if (!previewMode) {
                  modifyItemLayout({
                    item,
                    state: { ...item?.data?.state, commentary: { ...item?.data?.state?.commentary, saved: false } },
                  });
                }
              }}
              dangerouslySetInnerHTML={createMarkup(commentary?.value)}
            />
          ) : (
            <div
              className={`local-commentary report-local-widget-commentary mb-3 position-relative influence-description z-max`}
              ref={container}
            >
              <DescriptionTinyEditor
                ref={editorRef}
                editorToEdit={editorToEdit}
                value={commentary?.value}
                setValue={(value) => {
                  let heightSpanDiff = getHeightSpanDiff();
                  modifyItemLayout({
                    item,
                    state: {
                      ...item?.data?.state,
                      commentary: { ...item?.data?.state?.commentary, value: value, userTyping: true },
                    },
                    rowSpan: item?.rowSpan + heightSpanDiff,
                  });
                }}
                index={0}
                placeholder='Start typing'
                reportWidget={true}
                minHeight={100}
                additionalButtons={' | removeCommentary'}
                additionalSetupFunction={additionalSetupFunction}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                keyupfunction={keyupfunction}
                modifyItemLayout={modifyItemLayout}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ReportEmptyWidget = (props) => {
  const { previewMode } = props;
  const emptyElement = useRef();
  const hiddingParentElement = useRef();
  hiddingParentElement.current = () => {
    let firstParentElement = emptyElement.current.parentElement?.parentElement;
    let secondParentElement = firstParentElement?.parentElement?.parentElement;
    if (firstParentElement) {
      firstParentElement.style.visibility = 'hidden';
    }
    if (secondParentElement) {
      secondParentElement.classList.add('reports-empty-widget-container');
      if (previewMode) {
        secondParentElement?.parentElement?.classList.add('reports-empty-widget-container-preview');
      }
    }
  };

  useEffect(() => {
    hiddingParentElement.current();
  }, []);
  return <div ref={emptyElement} />;
};

export { ReportEmptyWidget, LocalWidgetCommentary };
export default CommentaryComponent;
