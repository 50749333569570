import { useContext, useRef, useState } from 'react';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import { store } from '../../Store';
import { useFeedbackItemCalls } from '../../Common/ThumbsDown';
import { createSectionName } from '../helpers/controlActiveFilters';
import useIsMobile from '../utilities/useIsMobile';
import preventLink from '../TagsList/prevenLink';
import useOutsideElement from '../utilities/useOutsideElement';
import getUrlParam from '../utilities/getUrlParam';
import { useParams } from 'react-router-dom-v5-compat';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';

const FeedbackOnItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults } = state;

  const { contentPage, item, index, updateFeedback, feedback, keywordsFromLists, customClass } = props;
  const feedbackProperties = {
    contentPage,
    item,
    index,
    updateFeedback,
    feedback,
    keywordsFromLists,
  };
  const sectionName = createSectionName();
  const section = contentResults[activeResults][sectionName];
  const { feedItemFlags } = section ?? {};

  const feedItem = feedItemFlags?.[index];
  const editFeedbackCondition = contentPage ? !!feedback?.ratingReason : !!feedItem?.ratingReason;

  const isMobile = useIsMobile(1175);
  const singleButtonCondition = isMobile || editFeedbackCondition;

  const topicParam = getUrlParam('topic-id');
  const isTopicANumber = topicParam ? Number.isInteger(parseInt(topicParam)) : false;

  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);

  return (
    <>
      {(isTopicANumber || inboxSection) && (
        <div
          className={`flex-centered general-feedback-container ${
            singleButtonCondition
              ? `${contentPage ? '' : 'item-share-feedback-container-single-button'}`
              : 'justify-content-between'
          } ${
            contentPage
              ? 'pt-2 pb-3 border-bottom'
              : 'border-top rounded-bottom item-share-feedback-container item-size-text'
          } ${customClass ?? ''}`}
        >
          <div className={`d-flex align-items-lg-center`}>
            <p className='main-subtle-text mb-0 mr-1'>This update has been marked as irrelevant</p>
          </div>
          <div>
            {singleButtonCondition ? (
              <FeedbackSingleButton {...feedbackProperties} />
            ) : (
              <FeedbackButtons {...feedbackProperties} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

const FeedbackSingleButton = (props) => {
  //AE: Keep properties in this way to know exactly what are needed for the component
  const { contentPage, item, index, updateFeedback, feedback, keywordsFromLists } = props;
  const feedbackProperties = {
    contentPage,
    item,
    index,
    updateFeedback,
    feedback,
    keywordsFromLists,
  };
  const [isOpen, setIsOpen] = useState(false);
  const actionsElement = useRef();
  const hideActions = () => {
    setIsOpen(false);
  };
  useOutsideElement(actionsElement, hideActions);

  return (
    <div className='position-relative' ref={actionsElement}>
      <button
        className={`indented-button general-button circle-button item-actions-button item-actions-button-feedback ${
          isOpen ? 'active' : ''
        }`}
        onClick={(e) => {
          preventLink(e);
          setIsOpen(!isOpen);
        }}
      >
        Feedback actions
      </button>
      {isOpen && (
        <div className='box box-shadow py-2 item-actions-box item-actions-box-feedback'>
          <FeedbackButtons {...feedbackProperties} isFromButton={true} />
        </div>
      )}
    </div>
  );
};

const FeedbackButtons = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { contentResults, activeResults } = state;

  const { item, index, contentPage, updateFeedback, feedback, keywordsFromLists } = props;
  const [loadingStates, setLoadingStates] = useState({
    view: false,
    undo: false,
  });

  const { removeFeedback } = useFeedbackItemCalls();

  const sectionName = createSectionName();
  const section = contentResults[activeResults][sectionName];
  const { feedItemFlags } = section ?? {};

  const feedItem = feedItemFlags?.[index];
  const { explanations } = feedItem ?? {};
  const editFeedbackCondition = contentPage ? !!feedback?.ratingReason : !!feedItem?.ratingReason;

  const { generalApiCall } = useGeneralApiCall();

  const getItemFeedback = async () => {
    const { objectID, parentObjectID } = item;
    const pathname = `/api/feed/item-feedback?objectId=${parentObjectID ?? objectID}`;
    const method = 'get';
    return await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
    });
  };

  const removeFeedbackReason = async () => {
    const { objectID, parentObjectID } = item;
    setLoadingStates({ ...loadingStates, undo: true });
    let results = await removeFeedback({
      objectId: parentObjectID ?? objectID,
    });
    if (results?.status === 200) {
      let newSection = { ...section };
      if (index !== undefined && index >= 0) {
        newSection.feedItemFlags[index] = {
          ...newSection.feedItemFlags[index],
          ratingReason: null,
          ratingScored: null,
        };
      }
      if (contentPage) {
        if (updateFeedback) {
          //AE: CASE FOR INFLUENCE ITEMS
          updateFeedback({ feedback: null });
        } else {
          let parameter = sectionName === 'LibraryMaterial' ? 'content' : 'new';
          newSection[parameter] = {
            ...newSection[parameter],
            feedback: null,
          };
        }
      }

      let newContentResults = {
        ...contentResults,
        [activeResults]: {
          [sectionName]: newSection,
        },
      };
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'contentResults',
        value: newContentResults,
      });
    }
  };

  return (
    <>
      <button
        className={`general-button nowrap-item outline-active-button px-3 py-1 mr-3`}
        onClick={(e) => {
          removeFeedbackReason();
          e.stopPropagation();
          e.preventDefault();
        }}
        disabled={loadingStates?.undo}
      >
        {loadingStates?.undo ? <i className='text-light-blue fas fa-spinner fa-spin d-inline-block mr-2' /> : ''} Undo
      </button>
      <button
        className={`general-button nowrap-item action-button px-3 py-1 border-general border-light-blue`}
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          setLoadingStates({ ...loadingStates, view: true });
          const feedback = await getItemFeedback();
          if (feedback) {
            const searchTermMatches = contentPage
              ? keywordsFromLists
              : explanations?.reduce((accumulator, currentValue) => {
                  let terms = currentValue?.searchTermMatches;
                  terms?.forEach((item) => {
                    if (!accumulator.includes(item?.searchTerm)) {
                      accumulator.push(item?.searchTerm);
                    }
                  });
                  return accumulator;
                }, []);
            dispatch({
              type: 'MODIFY_SECTION',
              parameter: 'feedbackItemPopup',
              value: {
                isHandPicked: feedItem?.isHandPicked,
                ratingScored: 'Negative',
                isOpen: true,
                item,
                index,
                keywords: searchTermMatches,
                feedback,
                contentPage,
                updateFeedback,
              },
            });
            setLoadingStates({ ...loadingStates, view: false });
          }
        }}
      >
        {loadingStates?.view ? <i className='fas fa-spinner fa-spin d-inline-block mr-2' /> : ''}
        {editFeedbackCondition ? 'Edit' : 'Share'} feedback
      </button>
    </>
  );
};

export default FeedbackOnItem;
